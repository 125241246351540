import React, { useEffect, useState } from "react";
import CustomInput from "../CustomInput";
import { Center, Container, Td, Tr } from "@chakra-ui/react";
import CustomButton1 from "../CustomButton1";
import TableView from "../TableView";
import { toast } from "react-toastify";
import { pdf_update_api } from "../../utils/apis";
import { LoaderFull } from "../Loader/LoaderFull";
import moment from "moment";

export const EditPolicy = ({
  remarkData,
  data,
  setShowEditPolicy,
  documentId,
}) => {
  const [updateData, setUpdateData] = useState({});
  const [showEditData, setShowEditData] = useState(false);
  const [remark, setRemark] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setUpdateData(data);
    setShowEditData(true);
    setRemark(remarkData);
  }, []);

  const update_policy = async () => {
    try {
      setShowLoader(true);
      const obj = {
        document_id: documentId,
        remark: remark || "",
        file_details: { ...updateData },
      };
      const response = await pdf_update_api(obj);
      setShowLoader(false);
      if (response.success) {
        toast.success("Updated Successfully", {
          toastId: "1",
          autoClose: 2000,
        });
        setShowEditPolicy(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {showEditData && (
        <>
          <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
            <TableView
              headData={["item", "value"]}
              body={
                <>
                  <Tr>
                    <Td>Insurance Company Name</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Insurance_company_name}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Insurance_company_name: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Insurance Plan Name</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Insurance_plan_name}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Insurance_plan_name: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Insurance Policy Type</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Insurance_policy_type}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Insurance_policy_type: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Insurance Policy Number</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Insurance_policy_number}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Insurance_policy_number: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Vehicle Registration Number</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Vehicle_registration_number}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Vehicle_registration_number: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Engine Number</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Engine_number}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Engine_number: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Chassis Number</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Chassis_number}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Chassis_number: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Policyholder Name</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Policyholder_name}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Policyholder_name: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Policyholder Address</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Policyholder_address}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Policyholder_address: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Policyholder Phone Number</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Policyholder_phone_number}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Policyholder_phone_number: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Policyholder Emailid</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Policyholder_emailid}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Policyholder_emailid: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Intermediary Code</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Intermediary_code}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Intermediary_code: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Intermediary Name</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Intermediary_name}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Intermediary_name: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Intermediary Phone Number</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Intermediary_phone_number}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Intermediary_phone_number: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Intermediary Emailid</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Intermediary_emailid}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Intermediary_emailid: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Total Premium Paid</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Total_premium_paid}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Total_premium_paid: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Own Damage Premium</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Own_damage_premium}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Own_damage_premium: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Base Premium</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        value={updateData?.Base_premium}
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            Base_premium: e.target.value,
                          })
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Policy Start Date</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        type={"date"}
                        w={"auto"}
                        value={moment(
                          updateData?.Policy_start_date,
                          "DD/MM/YYYY"
                        ).format("YYYY-MM-DD")}
                        onChange={(e) => {
                          setUpdateData({
                            ...updateData,
                            Policy_start_date: moment(e.target.value).format(
                              "DD/MM/YYYY"
                            ),
                          });
                        }}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Policy Expiry Date</Td>
                    <Td>
                      <CustomInput
                        placeholder={"Enter Expiry Date"}
                        type={"date"}
                        value={moment(
                          updateData?.Policy_expiry_date,
                          "DD/MM/YYYY"
                        ).format("YYYY-MM-DD")}
                        onChange={(e) => {
                          if (e.target.value.length == 10) {
                            setUpdateData({
                              ...updateData,
                              Policy_expiry_date: moment(e.target.value).format(
                                "DD/MM/YYYY"
                              ),
                            });
                          }
                        }}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Policy Issuance Date</Td>
                    <Td>
                      <CustomInput
                        placeholder={"Enter Expiry Date"}
                        type={"date"}
                        value={moment(
                          updateData?.Policy_issuance_date,
                          "DD/MM/YYYY"
                        ).format("YYYY-MM-DD")}
                        onChange={(e) => {
                          if (e.target.value.length == 10) {
                            setUpdateData({
                              ...updateData,
                              Policy_issuance_date: moment(
                                e.target.value
                              ).format("DD/MM/YYYY"),
                            });
                          }
                        }}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Remark</Td>
                    <Td>
                      <CustomInput
                        maxW={"auto"}
                        w={"auto"}
                        placeholder={"Remark"}
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    </Td>
                  </Tr>
                </>
              }
            />
          </div>

          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              gap: "2rem",
            }}
          >
            <Center>
              <CustomButton1
                title={"Update"}
                onClick={update_policy}
                bg={"blue"}
              />
            </Center>
          </Container>
        </>
      )}
      {showLoader && <LoaderFull loading={showLoader} />}
    </>
  );
};

export const ShowPolicy = ({ data }) => {
  return (
    <>
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        <TableView
          headData={["item", "value"]}
          body={
            <>
              <Tr>
                <Td>Insurance Company Name</Td>
                <Td>{data?.Insurance_company_name}</Td>
              </Tr>
              <Tr>
                <Td>Insurance Plan Name</Td>
                <Td>{data?.Insurance_plan_name}</Td>
              </Tr>
              <Tr>
                <Td>Insurance Policy Type</Td>
                <Td>{data?.Insurance_policy_type}</Td>
              </Tr>
              <Tr>
                <Td>Insurance Policy Number</Td>
                <Td>{data?.Insurance_policy_number}</Td>
              </Tr>
              <Tr>
                <Td>Vehicle Registration Number</Td>
                <Td>{data?.Vehicle_registration_number}</Td>
              </Tr>
              <Tr>
                <Td>Engine Number</Td>
                <Td>{data?.Engine_number}</Td>
              </Tr>
              <Tr>
                <Td>Chassis Number</Td>
                <Td>{data?.Chassis_number}</Td>
              </Tr>
              <Tr>
                <Td>Policyholder Name</Td>
                <Td>{data?.Policyholder_name}</Td>
              </Tr>
              <Tr>
                <Td>Policyholder Address</Td>
                <Td>{data?.Policyholder_address}</Td>
              </Tr>
              <Tr>
                <Td>Policyholder Phone Number</Td>
                <Td>{data?.Policyholder_phone_number}</Td>
              </Tr>
              <Tr>
                <Td>Policyholder Emailid</Td>
                <Td>{data?.Policyholder_emailid}</Td>
              </Tr>
              <Tr>
                <Td>Intermediary Code</Td>
                <Td>{data?.Intermediary_code}</Td>
              </Tr>
              <Tr>
                <Td>Intermediary Name</Td>
                <Td>{data?.Intermediary_name}</Td>
              </Tr>
              <Tr>
                <Td>Intermediary Phone Number</Td>
                <Td>{data?.Intermediary_phone_number}</Td>
              </Tr>
              <Tr>
                <Td>Intermediary Emailid</Td>
                <Td>{data?.Intermediary_emailid}</Td>
              </Tr>
              <Tr>
                <Td>Total Premium Paid</Td>
                <Td>{data?.Total_premium_paid}</Td>
              </Tr>
              <Tr>
                <Td>Own Damage Premium</Td>
                <Td>{data?.Own_damage_premium}</Td>
              </Tr>
              <Tr>
                <Td>Base Premium</Td>
                <Td>{data?.Base_premium}</Td>
              </Tr>
              <Tr>
                <Td>Policy Start Date</Td>
                <Td>{data?.Policy_start_date}</Td>
              </Tr>
              <Tr>
                <Td>Policy Expiry Date</Td>
                <Td>{data?.Policy_expiry_date}</Td>
              </Tr>
              <Tr>
                <Td>Policy Issuance Date</Td>
                <Td>{data?.Policy_issuance_date}</Td>
              </Tr>
            </>
          }
        />
      </div>
    </>
  );
};
