import {
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import CustomInput from "./CustomInput";
import CustomButton1 from "./CustomButton1";
import { help_expert_api, help_expert_api2 } from "../utils/apis";
import { ID, emailRegex, infoMess } from "../utils/utils";
import { toast } from "react-toastify";
import SelectImage from "./SelectImage";

const AskExpert = ({ bg }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  useEffect(() => {}, []);

  const sendQuote = async () => {
    if (
      !data.Name ||
      !data.Email ||
      !data.mobile ||
      !data.mess ||
      !data?.file?.name
    ) {
      infoMess("all fields are required");
      return;
    }
    if (!emailRegex.test(data.Email)) {
      infoMess("Invalid email address");
      return;
    }
    try {
      setLoading(true);
      const body = new FormData();
      body.append("full_name", data?.Name.trim());
      body.append("email_address", data?.Email);
      body.append("mobile", data?.mobile);
      body.append("description", data?.mess.trim());
      body.append("file", data?.file);
      const response = await help_expert_api2(body);
      setLoading(false);
      if (response?.success) {
        onClose();
        setData({});
        toast.success(response?.message, {
          toastId: "1",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        isOpen && sendQuote();
      }
    },
    [data]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);
  return (
    <div>
      <Text
        bg={bg}
        paddingBlock={1}
        paddingInline={2}
        borderRadius={5}
        cursor={"pointer"}
        color={"#fff"}
        fontWeight={"bold"}
        onClick={() => onOpen()}
        fontSize={["14px", "sm", "md"]}
      >
        Help
      </Text>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Help</DrawerHeader>
          <DrawerBody>
            <CustomInput
              label={"Full Name*"}
              value={data.Name}
              onChange={(e) => setData({ ...data, Name: e.target.value })}
              placeholder={"Enter Full Name"}
            />
            <CustomInput
              label={"Email Address*"}
              value={data.Email}
              onChange={(e) => setData({ ...data, Email: e.target.value })}
              placeholder={"Enter Email Address"}
            />
            <CustomInput
              label={"Phone Number*"}
              value={data.mobile}
              onChange={(e) =>
                e.target.value.length < 11 &&
                setData({
                  ...data,
                  mobile: e.target.value.replace(/([a-zA-Z ])/g, ""),
                })
              }
              placeholder={"Enter Mobile Number"}
            />
            <CustomInput
              label={"Your Message Here*"}
              value={data.mess}
              onChange={(e) => setData({ ...data, mess: e.target.value })}
              placeholder={"Enter Your Message Here"}
              area
            />
            <SelectImage
              label={"Select file"}
              doctype={".png,.jpeg,.jpg"}
              filename={data?.file?.name}
              onChange={(e) => setData({ ...data, file: e.target.files[0] })}
            />
            <Center mt={4} gap={10}>
              <CustomButton1
                bg={"#727b82"}
                title={"Cancel"}
                onClick={onClose}
              />
              <CustomButton1
                title={"Submit"}
                loading={loading}
                onClick={sendQuote}
              />
            </Center>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default AskExpert;
