import React from "react";
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  useDisclosure,
  Link as Linkchakra,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  Center,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import { AiOutlineSecurityScan, AiOutlineSetting } from "react-icons/ai";
import { IoLogOut } from "react-icons/io5";
import { MdOutlinePolicy } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { BiArchiveIn } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { IoMdShareAlt } from "react-icons/io";
import CustomPopup from "./CustomPopup";
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { CopyIcon } from "@chakra-ui/icons";
import { successMess } from "../utils/utils";

const Setting = () => {
  const [isSmallThan375] = useMediaQuery("(max-width: 375px)");
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const link = "https://smartdocs365.com/";
  const text = "SmartDocs365";
  const cancelRef = React.useRef();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isLOpen,
    onOpen: onLOpen,
    onClose: onLClose,
  } = useDisclosure();
  const {
    isOpen: isSOpen,
    onOpen: onSOpen,
    onClose: onSClose,
  } = useDisclosure();
  async function copyToClip(v) {
    await navigator.clipboard.writeText(v);
    successMess("Copied!");
  }
  return (
    <>
      <Popover placement="bottom-end" isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button
            borderRadius={"full"}
            bg={"transparent"}
            px={0}
            _hover={{ bg: "transparent" }}
            onClick={onOpen}
            outline={"none"}
            border={"none"}
          >
            <AiOutlineSetting
              size={25}
              style={{
                backgroundColor: "#fff",
                height: isSmallThan375 ? 20 : 30,
                width: isSmallThan375 ? 20 : 30,
                padding: 4,
                borderRadius: 20,
              }}
            />
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent w={"auto"} h={"auto"}>
            <PopoverArrow />
            <PopoverBody onClick={() => onClose()}>
              <Link to={"/profile"} className="activeLink">
                <CgProfile />
                Profile
              </Link>
              <Link onClick={onSOpen} className="activeLink">
                <IoMdShareAlt />
                Share app
              </Link>

              <Link className="activeLink" onClick={onLOpen}>
                <IoLogOut />
                Log Out
              </Link>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>

      <CustomPopup
        isOpen={isLOpen}
        onClick={() => {
          onLClose();
          localStorage.removeItem("user");
          localStorage.clear();
          navigate("/"); //
        }}
        onClose={onLClose}
        title={"Log Out"}
        mess={"Are you sure you want to log out"}
        b_name={"Log Out"}
      />
      <AlertDialog
        isOpen={isSOpen}
        leastDestructiveRef={cancelRef}
        onClose={onSClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Share App
            </AlertDialogHeader>
            <AlertDialogBody>
              <Center gap={5} mb={3}>
                <WhatsappShareButton title={text} separator=":: " url={link}>
                  <WhatsappIcon round={true} size={40} />
                </WhatsappShareButton>
                <EmailShareButton
                  // subject={}
                  body=""
                  url={`${text} \n ${link}`}
                >
                  <EmailIcon round={true} size={40} />
                </EmailShareButton>
                <Box
                  onClick={() => {
                    copyToClip(link);
                    onSClose();
                  }}
                  bg={"gray.400"}
                  h={10}
                  w={10}
                  borderRadius={"full"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  display={"flex"}
                >
                  <CopyIcon h={5} w={5} />
                </Box>
              </Center>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Setting;
