import React, { useEffect, useState } from "react";
// import "./mainpage.css";
// import './CSS/styles.css'
import "./CSS/styles.css";
import { FooterData, HeaderData } from "./MainPage";
import about1 from "../../assets/about1.png";
import about2 from "../../assets/about2.png";
import about3 from "../../assets/about31.jpeg";
import bg1 from "../../assets/bg1.png";
// import bg2 from '../../assets/bg2.png'
import bg3 from "../../assets/bg3.png";
import bg4 from "../../assets/new.png";
import CustomInput from "../../components/CustomInput";
import { Bloags } from "../../components/Bloags/Bloags";
import { emailRegex, infoMess } from "../../utils/utils";
import { help_expert_api, help_expert_api2 } from "../../utils/apis";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import store from "../../redux/Store";
import { get_plan_subcription } from "../../redux/slice/userSlice";
import { useNavigate } from "react-router-dom";
import { FadeLoader, MoonLoader } from "react-spinners";

export const AboutUs = () => {
  return (
    <>
      <HeaderData />
      <div id="static_about_page" className="page-container">
        <div id="about_us_section">
          <div id="about_us_section_interior" className="fixed-width">
            <img src={about1} id="about_us_image" />
            <div id="about_us_info" className="fixed-width">
              <h2>About Us</h2>
              <p>
                SmartDocs365 is An AI technology startup based in Mumbai.. We
                are building a platform that will empower our users to simplify
                and automate their business smartly. Effortlessly manage
                policies/ documents, boost efficiency, and focus on what matters
                most: your clients. Your streamlined journey starts here.
              </p>
            </div>
            <div className="clear"></div>
          </div>
        </div>

        <div id="vision_section">
          <img src={bg1} className="diagonal-border diagonal-border-top" />
          <img src={bg3} className="diagonal-border diagonal-border-bottom" />
          <div id="vision_section_interior" className="fixed-width">
            <img src={about2} id="targets_img" />
            <div id="vision_section_info">
              <h2>Our Vision</h2>
              <p>
                We believe that the future of business lies with artificial
                intelligence. We want to give AI powered tools and technology
                benefits to everyone at a reasonable rate. SmartDocs365
                envisions a future where document management is effortlessly
                smart, connecting businesses and individuals with simplicity and
                innovation.
              </p>
            </div>
          </div>
        </div>

        <div id="founder_section">
          <div id="founder_section_interior" className="fixed-width">
            <div id="founder_section_info">
              <div id="founder_image_container_mobile">
                {/* <img
                  src="/assets/static_about_page/jeff3-0f0d2c15c368c5893e25a0006acc779e0d2c25fd595eac71238176226bbf9b37.jpg"
                  id="founder_image_mobile"
                /> */}
              </div>
              <h2>Our Founder</h2>
              <p>
                Ankit R Mehta, the driving force at SmartDocs365, believes in
                empowering people. With a CA, CS, LLB background and deep
                insights into the insurance industry, he's leading our mission
                to simplify document management. Join us in creating a future
                where efficiency meets compassion.
              </p>
            </div>
            <div id="founder_image_container">
              <img src={about3} id="founder_image" />
            </div>
          </div>
        </div>
      </div>
      <FooterData />
    </>
  );
};

export const ContactUs = () => {
  const [data, setData] = useState({});
  const [files, setFiles] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const sendQuote = async () => {
    if (
      !data?.Name ||
      !data?.Name.trim() ||
      !data?.Email ||
      !data?.mobile ||
      !data?.mess ||
      !data?.mess.trim()
    ) {
      infoMess("all fields are required");
      return;
    }
    if (!emailRegex.test(data.Email)) {
      infoMess("Invalid email address");
      return;
    }
    try {
      setLoading(true);
      const body = new FormData();
      body.append("full_name", data?.Name.trim());
      body.append("email_address", data?.Email);
      body.append("mobile", data?.mobile);
      body.append("description", data?.mess.trim());
      body.append("file", files);
      const response = await help_expert_api2(body);
      setLoading(false);
      if (response?.success) {
        toast.success(response?.message, {
          toastId: "1",
        });
        navigate("/"); //
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <>
      <HeaderData />

      <div id="static_contact_page" className="page-container">
        <div className="fixed-width">
          <div className="pure-g">
            <div className="pure-u-1-1 pure-u-md-2-3">
              <h1 className="page-title">Contact Us</h1>
              <p>
                Please fill out the form below to contact us, or email{" "}
                <a href="mailto:hello@Smartdocs365.com">
                  support@smartdocs365.com
                </a>
                .
              </p>
              <br />
              <form id="contact_form" rel="nofollow" method="post">
                <div id="contact_main_form">
                  <table>
                    <tbody>
                      <tr>
                        <td>Your name:&nbsp;</td>
                        <td>
                          <CustomInput
                            id="form_name"
                            name="n"
                            type="text"
                            placeholder="First and last name"
                            onChange={(e) => {
                              if (e.target.value.length <= 25) {
                                setData({ ...data, Name: e.target.value });
                              }
                            }}
                            value={data.Name}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Your mail id:&nbsp;</td>
                        <td>
                          <CustomInput
                            id="form_email"
                            name="e"
                            type="text"
                            placeholder="your@email.com"
                            value={data.Email}
                            onChange={(e) =>
                              setData({ ...data, Email: e.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Mobile No. :&nbsp;</td>
                        <td>
                          <CustomInput
                            id="form_phone"
                            name="p"
                            type="text"
                            placeholder="9796959291"
                            value={data.mobile}
                            onChange={(e) =>
                              e.target.value.length < 11 &&
                              setData({
                                ...data,
                                mobile: e.target.value.replace(
                                  /([a-zA-Z ])/g,
                                  ""
                                ),
                              })
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  Message:
                  <br />
                  <CustomInput
                    placeholder={"Please Enter Comment Here"}
                    area={"400"}
                    value={data.mess}
                    onChange={(e) => setData({ ...data, mess: e.target.value })}
                  ></CustomInput>
                  <br />
                  <CustomInput
                    id="form_phone"
                    name="p"
                    type="file"
                    format={"image/*"}
                    onChange={(event) => setFiles(event.target.files[0])}
                  />
                  <br />
                  <div
                    style={{
                      height: 40,
                      width: 150,
                      display: "flex",
                      backgroundColor: "green",
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 10,
                    }}
                    onClick={sendQuote}
                  >
                    {loading ? (
                      <MoonLoader size={20} color="#fff" />
                    ) : (
                      "Send Message"
                    )}
                  </div>
                </div>

                <div id="contact_thinking">
                  <img
                    src="/assets/loading-af57a1d421eb89eefadca298f2485822dde817feab5b1d00e7e6eff3bc93893f.gif"
                    alt="Loading..."
                  />
                </div>

                <div id="contact_received">
                  Success!
                  <br />
                  <br />
                  Thank you for your message.
                </div>

                <div id="contact_error">
                  An error has occurred.
                  <br />
                  <br />
                  Please try again.
                </div>
              </form>
            </div>
            {/* <div className="pure-u-1-1 pure-u-md-1-3">
              <div className="side-blurb">
                <h2>About Smart Docs 365</h2>
                <p>
                  Smart Docs 365 is an AI-powered platform that answers any
                  question you have about your documents. Simply upload a PDF
                  and let Smartdocs365 analyze it for you. Then have a
                  conversation with Smartdocs365 to get answers to your
                  questions. Smartdocs365 is a great tool for financial
                  analysts, lawyers, accountants, scientists, and anyone else
                  who needs to analyze documents.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <FooterData />
    </>
  );
};

export const PlansData = () => {
  const [isCheckedYes, setIsCheckedYes] = useState(false);
  const [isCheckedNo, setIsCheckedNo] = useState(false);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "cover_yes" && checked) {
      setIsCheckedYes(true);
      setIsCheckedNo(false);
    } else if (name === "cover_no" && checked) {
      setIsCheckedYes(false);
      setIsCheckedNo(true);
    } else {
      setIsCheckedYes(false);
      setIsCheckedNo(false);
    }
  };

  const { plan_list } = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    store.dispatch(get_plan_subcription());
  }, []);
  return (
    <div
      style={{
        // width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderData />
      <div id="static_pricing_page" className="page-container">
        <div
          className="fixed-width np_01"
          style={{
            flexDirection: "column",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <br />

          <div style={{ color: "black", fontSize: "24px", fontWeight: "bold" }}>
            Choose Your Plan
          </div>
          <div className="clear"></div>

          <div id="plans">
            {plan_list?.map((v, i) => (
              <div key={i} className="plan popular" id="plus_plan">
                <img
                  className="banner"
                  src="/assets/popular_banner-ad719d0ca36bfadd41ae272b8f1db643120266a07f62b8e03da9ae767762f236.png"
                />
                <h2>{v?.plan_name}</h2>

                <div className="pricing">
                  <span className="dollar">Rs</span>
                  <span className="price">{v?.plan_price}</span>
                  <span className="permo">/{v?.plan_duration} days</span>
                  <div className="discount" style={{ display: "none" }}>
                    (<span className="discount-percent-off">0</span>% off
                    Rs1000/mo, paid annually)
                  </div>
                </div>

                <div className="inner">
                  <div className="benefits">
                    <div className="benefit">
                      Upto {v?.pdf_limit} pdf for {v?.plan_duration} days
                    </div>
                    <div className="benefit">
                      Recharge again after {v?.plan_duration} days or{" "}
                      {v?.pdf_limit} PDF usage whichever is earlier
                    </div>
                    <div className="benefit">File size 10 mb per pdf</div>
                    <div className="benefit">
                      Human Loop (Our manual review for accuracy):
                    </div>
                    <div className="benefit">
                      {v?.pdf_limit} extra per month{" "}
                    </div>
                    {/* <div className="benefit">Email support</div> */}
                  </div>

                  <div className="start">
                    <div
                      className="button get-started-button"
                      onClick={() => {
                        if (localStorage.getItem("token")) {
                          // navigate('')
                        } else {
                          navigate("/login");
                        }
                      }}
                    >
                      Get Started
                    </div>
                    <div className="your-plan" style={{ display: "none" }}>
                      This is your plan.
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="clear"></div>
          </div>

          {/* <div className="clear"></div> */}
          {/* <div
            style={{
              color: "black",
              display: "flex",
              gap: "1rem",
              marginBottom: "1rem",
              marginTop: "2rem",
              fontSize: "18px",
              alignItems: "center",
            }}
          >
            <div>Addon cover required : </div>
            <label htmlFor="cover_yes">Yes</label>
            <input
              style={{ height: "18px", width: "18px", cursor: "pointer" }}
              type="checkbox"
              name="cover_yes"
              id="cover_yes"
              checked={isCheckedYes}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="cover_no">No</label>
            <input
              style={{ height: "18px", width: "18px", cursor: "pointer" }}
              type="checkbox"
              name="cover_no"
              id="cover_no"
              checked={isCheckedNo}
              onChange={handleCheckboxChange}
            />
          </div> */}
        </div>
      </div>
      <div style={{ flex: 1 }} />
      <FooterData />
    </div>
  );
};

export const UpdatesData = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderData />
      <Bloags />
      <div style={{ flex: 1 }} />
      <FooterData />
    </div>
  );
};
