import { toast } from "react-toastify";
import { errorMess, successMess } from "./utils";
import { token, userId } from "../redux/slice/userSlice";
import axios from "axios";
import { getNotifications } from "../redux/slice/notificationSlice";
import store from "../redux/Store";

export const base_url = "https://api.smartdocs365.com/api/";

// Done
export const handle_login = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "login", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "content-type": "application/json",
      },
    });

    const res = await response.json();
    setLoading(false);
    console.log("login", res);
    if (res.success) {
      // window.location.reload();
      // if(body?.isReload) {
      // window.location.reload();
      // }
      successMess("Logged In Successfully");
      return res;
    } else {
      errorMess(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

// Done
export const forgot_pass = async (email, setLoading) => {
  try {
    setLoading(2);
    const responce = await fetch(base_url + "update/forgot-password/" + email, {
      method: "GET",
    });
    const res = await responce.json();
    setLoading("");

    return res;
  } catch (error) {
    setLoading("");
  }
};
export const seen_notification = async (id) => {
  try {
    const responce = await fetch(
      base_url + `notification/seen/notification_ID/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await responce.json();

    store.dispatch(getNotifications(token));

    return res;
  } catch (error) {}
};

// Done
export const register = async (body, setLoading) => {
  try {
    setLoading(5);
    const responce = await fetch(base_url + "user/register", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "content-type": "application/json",
      },
    });
    const res = await responce.json();
    setLoading("");

    if (res.success) {
      // window.location.reload();
      successMess("User Registered Successfully");
      return res;
    } else {
      errorMess(res.message);
      return res;
    }
  } catch (error) {
    setLoading("");
  }
};

// Profile APIs

// Profile Update

// Done
export const update_profile_api = async (body) => {
  try {
    const responce = await fetch(base_url + "user/profile-update/" + userId, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};
// Done
export const update_password_api = async (body) => {
  try {
    const responce = await fetch(base_url + "update/update-password", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// PDF list API
export const pdf_list_api = async () => {
  try {
    const responce = await fetch(base_url + "pdf/list-process-id/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// PDF Upload API

export const pdf_upload_api = async (body) => {
  try {
    const responce = await fetch(base_url + "upload-pdf/", {
      method: "POST",
      // body: JSON.stringify(body),
      body: body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// PDF Upload API

export const excel_upload_api = async (body) => {
  try {
    const responce = await fetch(base_url + "import-excel/", {
      method: "POST",
      // body: JSON.stringify(body),
      body: body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// Upload Data

// PDF Update API
export const pdf_update_api = async (body) => {
  try {
    const responce = await fetch(base_url + "pdf/update", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
    });
    const res = await responce.json();

    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// Delete PDF API
export const delete_pdf_api = async (id, token) => {
  try {
    const responce = await fetch(base_url + "pdf/delete-document-id/" + id, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// Subscription Plan API
export const subs_plan_list_api = async (token) => {
  try {
    const responce = await fetch(base_url + "subcription-plan/list/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// Subscription Plan API
export const subs_plan_purchase_api = async (id, token) => {
  try {
    const responce = await fetch(base_url + "recharge/purchase/plan-id/" + id, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// Update Recharge Status Plan API
export const update_plan_status_api = async (body, token) => {
  try {
    const responce = await fetch(base_url + "recharge/status-update", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// ASK to expert API
export const help_expert_api = async (body) => {
  try {
    const responce = await fetch(base_url + "questions/add", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const help_expert_api2 = async (body) => {
  try {
    const responce = await fetch(base_url + "questions-direct/add", {
      method: "POST",
      // body: JSON.stringify(body),
      body: body,
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// Download Excel File API
export const download_excel_file_api = async () => {
  try {
    const response = await axios.get(
      // "https://103.131.196.205:3033/api/download-demo-excel",
      `${base_url}download-demo-excel`,
      {
        responseType: "blob", // Important for binary data like files
      }
    );

    // Create a Blob from the response
    const blob = new Blob([response.data], { type: "application/xml" });

    // Create a link element and simulate a click to trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Demo.xlsx";
    link.click();
    // const res = await responce.json();
    // if (res.success) {
    //   return res;
    // } else {
    //   return res;
    // }
  } catch (error) {
    console.log(error);
  }
};

// Nortification API
export const nortification_api = async () => {
  try {
    const responce = await fetch(base_url + "notification/list", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// Blogs List API

export const blogs_list_api = async () => {
  try {
    const responce = await fetch(base_url + "blogs-direct/list/", {
      method: "GET",
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const blogs_list_api2 = async () => {
  try {
    const responce = await fetch(base_url + "blogs/list", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await responce.json();
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const send_otp = async (email, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(base_url + "user/otp-verification/" + email, {
      method: "GET",
    });
    const res = await responce.json();
    setLoading(false);
    return res;
  } catch (error) {
    console.log(error);
  }
};
