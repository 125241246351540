import React from "react";
import { FooterData, HeaderData } from "./MainPage/MainPage";
import "./MainPage/mainpage.css";

const TermCondition = () => {
  return (
    <>
      <HeaderData />
      <div id="static_privacy_page" className="page-container">
        <div
          className="fixed-width"
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          <h1
            className="page-title"
            style={{ fontSize: "24px", fontWeight: "bold" }}
          >
            Terms and Condition
          </h1>
          <div
            className="fixed-width"
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "1rem",
            }}
          >
            <p>
              Welcome to SmartDocs365.com. These Terms and Conditions govern
              your use of our website and services. By accessing or using our
              website and services, you agree to comply with and be bound by
              these terms. If you do not agree with these terms, please do not
              use our website or services.
            </p>
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>1. Services</h3>
            <p>
              1.1. Description: SmartDocs365 provides digital services as
              described on our website.
            </p>
            <p>
              1.2. Service Modifications: We reserve the right to modify or
              discontinue any service, temporarily or permanently, with or
              without notice. We are not liable to you or any third party for
              any modification, suspension, or discontinuation of services.
            </p>
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              2. User Conduct
            </h3>
            <p>
              2.1. Compliance: You agree to comply with all applicable laws and
              regulations when using our website and services. You are solely
              responsible for your conduct and any data, text, documents,
              information, graphics, photos, or other materials ("Content") that
              you submit, post, or display on our platform.
            </p>
            <p>
              2.2. Prohibited Activities: You must not engage in any activities
              that violate these terms or our policies, including but not
              limited to unauthorized access, data mining, or interference with
              the proper working of our services.
            </p>
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              3. Intellectual Property
            </h3>
            <p>
              3.1. Use of Content: You may not use, reproduce, distribute, or
              create derivative works based on our content without our express
              written consent.
            </p>
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              4. Confidentiality
            </h3>
            <p>
              4.1. Client Confidentiality: We respect the confidentiality of our
              clients' information. Any information shared with us in the course
              of our services will be treated with the utmost confidentiality.
            </p>
            <p>
              4.2. Non-Disclosure Agreement: For specific projects, a separate
              non-disclosure agreement (NDA) may be required to protect the
              confidentiality of sensitive information.
            </p>
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              5. Limitation of Liability
            </h3>
            <p>
              5.1. Disclaimer: SmartDocs365 Solutions provides services "as is"
              without any warranty or condition, express, implied, or statutory.
              We do not warrant that our services will be uninterrupted, secure,
              or error-free.
            </p>
            <p>
              5.2. Limitation of Liability: In no event shall SmartDocs365
              Solutions be liable for any indirect, incidental, special,
              consequential, or punitive damages, or any loss of profits or
              revenues, whether incurred directly or indirectly.
            </p>
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              6. Changes to Terms and Conditions
            </h3>
            <p>
              We reserve the right to update these terms and conditions at any
              time. The "Last Updated" date at the top of the document indicates
              when the latest changes were made. It is your responsibility to
              review these terms periodically.
            </p>
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              7. Contact Us
            </h3>
            <p>
              If you have any questions, concerns, or requests regarding these
              Terms and Conditions, please contact us at
              support@SmartDocs365.com. By using our website and services, you
              agree to abide by the terms outlined in these Terms and
              Conditions.
            </p>
            <p>Thank you for choosing SmartDocs365 Solutions!</p>
          </div>
          <br />
          <br />
        </div>
      </div>
      <FooterData />
    </>
  );
};

export default TermCondition;
