import React, { useEffect, useState } from "react";
import styles from "./PriceCard.module.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  subs_plan_list_api,
  subs_plan_purchase_api,
  update_plan_status_api,
} from "../../utils/apis";
import { toast } from "react-toastify";
import { LoaderFull } from "../Loader/LoaderFull";
import store from "../../redux/Store";
import { get_plan_subcription } from "../../redux/slice/userSlice";
import { useSelector } from "react-redux";
import "../../screens/MainPage/mainpage.css";
import PlanCmp from "../PlanCmp";

export const PriceCard = () => {
  const { plan_list, current_plan_id } = useSelector((state) => state.user);
  const [showLoader, setShowLoader] = useState(false);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const token = user?.consumer?.token;
  const [isCheckedYes, setIsCheckedYes] = useState(false);
  const [isCheckedNo, setIsCheckedNo] = useState(false);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "cover_yes" && checked) {
      setIsCheckedYes(true);
      setIsCheckedNo(false);
    } else if (name === "cover_no" && checked) {
      setIsCheckedYes(false);
      setIsCheckedNo(true);
    } else {
      setIsCheckedYes(false);
      setIsCheckedNo(false);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    store.dispatch(get_plan_subcription());
  }, []);

  const purchase_plane = async (plan_id) => {
    try {
      setShowLoader(true);
      const response = await subs_plan_purchase_api(plan_id, token);
      if (response.success) {
        // toast.success(response.message);
        const body = {
          recharge_id: response?.data?.recharge_id,
        };
        const res = await update_plan_status_api(body, token);
        if (res.success) {
          setShowLoader(false);
          toast(res.message, {
            toastId: "1",
            autoClose: 2000,
          });
        } else if (!res.success) {
          toast.error(res.message, {
            toastId: "1",
            autoClose: 2000,
          });
        }
      }
      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="static_pricing_page" className="page-container">
      <div
        className="fixed-width np_01"
        style={{
          flexDirection: "column",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <br />
        <div style={{ color: "black", fontSize: "24px", fontWeight: "bold" }}>
          Choose Your Plan
        </div>{" "}
        <div className="clear"></div>
        <div id="plans">
          {plan_list?.map((v, i) => (
            <PlanCmp
              planName={v?.plan_name}
              price={v?.plan_price}
              days={v?.plan_duration}
              line1={`Upto ${v?.pdf_limit} pdf for ${v?.plan_duration} days`}
              line2={`Recharge again after ${v?.plan_duration} days or ${v?.pdf_limit} PDF usage whichever is earlier`}
              // line3={v.line1 ? v.line1 : "File size 10 mb per pdf"}
              line3={v.line1 ? v.line1 : ".........."}
              line4={v.line2 ? v.line2 : ".........."}
              // line4={
              //   v.line2
              //     ? v.line2
              //     : "Human Loop (Our manual review for accuracy):"
              // }
              line5={v.line3 ? v.line3 : `..........`}
              // line5={v.line3 ? v.line3 : `${v?.pdf_limit} extra per month`}
              b_name={
                token
                  ? v?.plan_price ==
                    plan_list?.filter(
                      (val) => val.plan_id == current_plan_id
                    )[0]?.plan_price
                    ? "Activated Plan"
                    : v?.plan_price >
                      plan_list?.filter(
                        (val) => val.plan_id == current_plan_id
                      )[0]?.plan_price
                    ? "Upgrade Plan"
                    : "Downgrade Plan"
                  : "Get Started"
              }
              onClick={() => {
                if (token) {
                  v.plan_id == current_plan_id ||
                    navigate("/checkout", { state: v });
                  // v.plan_id == current_plan_id ||
                  //   purchase_plane(v?.plan_id);
                } else {
                  navigate("/login");
                }
              }}
            />
            // <div key={i} className="plan popular" id="plus_plan">
            //   <h2>{v?.plan_name}</h2>

            //   <div className="pricing">
            //     <span className="dollar">Rs</span>
            //     <span className="price">{v?.plan_price}</span>
            //     <span className="permo">/{v?.plan_duration} days</span>
            //   </div>

            //   <div className="inner">
            //     <div className="benefits" style={{ flexDirection: "column" }}>
            //       <div className="benefit">
            //         Upto {v?.pdf_limit} pdf for {v?.plan_duration} days
            //       </div>
            //       <div className="benefit">
            //         Recharge again after {v?.plan_duration} days or{" "}
            //         {v?.pdf_limit} PDF usage whichever is earlier
            //       </div>
            //       <div className="benefit">
            //         {v.line1 ? v.line1 : "File size 10 mb per pdf"}
            //       </div>
            //       <div className="benefit">
            //         {v.line2
            //           ? v.line2
            //           : "Human Loop (Our manual review for accuracy):"}
            //       </div>
            //       <div className="benefit">
            //         {v.line3 ? v.line3 : `${v?.pdf_limit} extra per month`}
            //       </div>
            //     </div>

            //     <div className="start">
            //       <div
            //         className="button get-started-button"
            //         style={{
            //           cursor: v.plan_id == current_plan_id && "default",
            //         }}
            //         onClick={() => {
            //           if (token) {
            //             v.plan_id == current_plan_id ||
            //               navigate("/checkout", { state: v });
            //             // v.plan_id == current_plan_id ||
            //             //   purchase_plane(v?.plan_id);
            //           } else {
            //             navigate("/login");
            //           }
            //         }}
            //       >
            //         {token
            //           ? v?.plan_price ==
            //             plan_list?.filter(
            //               (val) => val.plan_id == current_plan_id
            //             )[0]?.plan_price
            //             ? "Activated Plan"
            //             : v?.plan_price >
            //               plan_list?.filter(
            //                 (val) => val.plan_id == current_plan_id
            //               )[0]?.plan_price
            //             ? "Upgrade Plan"
            //             : "Downgrade Plan"
            //           : "Get Started"}
            //       </div>
            //       <div className="your-plan" style={{ display: "none" }}>
            //         This is your plan.
            //       </div>
            //     </div>
            //   </div>
            // </div>
          ))}

          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      {showLoader && <LoaderFull loading={showLoader} />}
    </div>
  );
};
