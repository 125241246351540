import "./App.css";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import Singup from "./screens/Singup";
import Addpolicy from "./screens/policy/Addpolicy";
import Notificationlist from "./screens/Notificationlist";
import React, { useEffect, useRef, useState } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./screens/Login";
import Layout from "./components/Layout";
import Home from "./screens/home/Home";
import Profile from "./screens/setting/Profile";
import NotificationDetail from "./screens/NotificationDetail";
import ResetPassword from "./screens/ResetPassword";
import AskExpert from "./components/AskExpert";
import { Center, Container, Heading, Stack, Text } from "@chakra-ui/react";
import CustomButton1 from "./components/CustomButton1";
import { MainPage } from "./screens/MainPage/MainPage";
import { AboutUs, ContactUs, UpdatesData } from "./screens/MainPage/AboutUs";
import { Privacy } from "./screens/Privacy/Privacy";
import Plans from "./screens/Plans";
import TermCondition from "./screens/TermCondition";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Checkout from "./screens/Checkout";
import TermsCondition from "./screens/TermsCondition";
import RechargeHistory from "./screens/setting/RechargeHistory";

function App() {
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const location = useLocation();

  useEffect(() => {
    if (user != null) {
      if (location.pathname == "/profile") {
        navigate("/profile");
        return;
      }
      if (location.pathname == "/all-notifications") {
        navigate("/all-notifications");
        return;
      }
      if (location.pathname == "/notification-detail") {
        navigate("/notification-detail");
        return;
      }
      navigate("/home");
      return;
    }
    // navigate("/");
  }, []);

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(window.navigator.onLine);
    };
    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  return (
    <>
      {isOnline ? (
        <Routes>
          <Route exact path="/" element={<MainPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/updates" element={<UpdatesData />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/term-condition" element={<TermCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<Layout />}>
              <Route path="/home" element={<Home />} />
              <Route path="/terms-condition" element={<TermsCondition />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/add-policy" element={<Addpolicy />} />
              <Route path="/all-notifications" element={<Notificationlist />} />
              <Route
                path="/notification-detail"
                element={<NotificationDetail />}
              />
              <Route path="profile" element={<Profile />} />
              <Route path="transaction-history" element={<RechargeHistory />} />
            </Route>
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="singup" element={<Singup />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      ) : (
        <NoInternet />
      )}
      {location.pathname == "/home" ||
        location.pathname == "/singup" ||
        location.pathname == "/reset-password" || (
          <Stack
            position={"fixed"}
            zIndex={1000}
            bottom={["4", "20"]}
            right={["2", "10"]}
            display={["flex", "none"]}
            style={{ display: "none" }}
          >
            <AskExpert bg={"blue"} />
          </Stack>
        )}
    </>
  );
}
function NoInternet() {
  return (
    <Container
      bg={"#f8f9fe"}
      display={"flex"}
      h={"100vh"}
      maxW={"100vw"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
    >
      <Heading color={"blue"}>No Internet Connection</Heading>
      <Text my={4}>Please check your connection</Text>
      <Center>
        <CustomButton1
          title={"Try Again"}
          onClick={() => window.location.reload()}
        />
      </Center>
    </Container>
  );
}
function PageNotFound() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Heading fontSize={"6xl"} color={"blue"}>
          404
        </Heading>
        <Heading fontSize={"2xl"} paddingBlock={2}>
          Page Not Found
        </Heading>
        <Text>Oops! You seem to be lost.</Text>
        <Text>
          Go to
          <Link
            to="/"
            style={{
              textDecorationLine: "underline",
              padding: 5,
            }}
          >
            {" "}
            Home{" "}
          </Link>
          page
        </Text>
      </div>
    </div>
  );
}
export default App;
