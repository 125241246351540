import {
  Box,
  Center,
  Flex,
  Text,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Button,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { successMess } from "../utils/utils";
import { CopyIcon } from "@chakra-ui/icons";
import { FiShare2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import axios from "axios";

const ShareOptions = ({ url, text, ml, mt, icon, mobile }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  async function copyToClip() {
    await navigator.clipboard.writeText(`${text} ${url}`);
    successMess("Copied!");
  }

  return (
    <Popover
      placement="bottom-end"
      isOpen={isOpen}
      onClose={onClose}
      arrowPadding={0}
    >
      <PopoverTrigger>
        {icon ? (
          <IconButton
            icon={
              <Tooltip title={"Share Policy"}>
                <FiShare2 size={20} cursor={"pointer"} />
              </Tooltip>
            }
            size="sm"
            bg={"red"}
            _hover={{ bg: "transparent" }}
            m={0}
            p={0}
            h={0}
            w={0}
            onClick={onOpen}
          />
        ) : (
          <Button
            bg={"transparent"}
            px={0}
            py={0}
            my={0}
            mx={0}
            h={0}
            w={0}
            _hover={{ bg: "transparent" }}
          >
            <Flex
              gap={2}
              align={"center"}
              cursor={"pointer"}
              mt={mt || 3}
              mb={2}
              ml={ml}
              onClick={onOpen}
            >
              <Tooltip title={"Share Policy"}>
                <FiShare2 size={20} cursor={"pointer"} />
              </Tooltip>
              {text && (
                <Text fontSize={"sm"} fontWeight={"medium"}>
                  {text}
                </Text>
              )}
            </Flex>
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent maxW={"250px"}>
        <PopoverArrow />
        <PopoverBody onClick={() => onClose()}>
          <Center gap={5}>
            {mobile && mobile != "NA" ? (
              <a
                href={`https://api.whatsapp.com/send/?phone=91${mobile}&text=${encodeURIComponent(
                  text,
                  url
                )}`}
                target="_blank"
              >
                {/* <WhatsappShareButton title={text} url={url}> */}
                <WhatsappIcon round={true} size={40} />
                {/* </WhatsappShareButton> */}
              </a>
            ) : (
              <WhatsappShareButton title={text} url={url}>
                <WhatsappIcon round={true} size={40} />
              </WhatsappShareButton>
            )}
            {/* <div onClick={sendMessage}>
              <WhatsappIcon onClick={sendMessage} round={true} size={40} />
            </div> */}
            {/* <WhatsappShareButton title={text} url={url}>
              <WhatsappIcon round={true} size={40} />
            </WhatsappShareButton> */}
            {/* <WhatsappShareButton title={text} url={url}>
              <WhatsappIcon round={true} size={40} />
            </WhatsappShareButton> */}
            {/* <EmailShareButton title={text} body={text} url={url}>
              <EmailIcon round={true} size={40} />
            </EmailShareButton> */}
            <EmailShareButton body={`${text} \n ${url}`}>
              <EmailIcon round={true} size={40} />
            </EmailShareButton>

            <Box
              onClick={() => copyToClip(url)}
              bg={"gray.400"}
              h={10}
              w={10}
              borderRadius={"full"}
              alignItems={"center"}
              justifyContent={"center"}
              display={"flex"}
            >
              <CopyIcon h={5} w={5} />
            </Box>
          </Center>
        </PopoverBody>
      </PopoverContent>
      {/* </Portal> */}
    </Popover>
  );
};

export default ShareOptions;
