import React from "react";
import { ClipLoader } from "react-spinners";

export const LoaderFull = ({ loading }) => {
    const override = {
        display: "block",
        margin: "0 auto",
      };
  return (
    <div
    style={{
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      width: "100vw",
      height: "100vh",
      zIndex: 10,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      position: "fixed",
    }}
  >
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: " translate(-50%, -50%)",
        maxWidth: "85%",
        width: "100%",
      }}
    >
      <ClipLoader
        color={"blue"}
        loading={loading}
        cssOverride={override}
        size={80}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  </div>
  );
};
