import {
  Card,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import BreadCrumName from "../components/BreadCrumName";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { seen_notification } from "../utils/apis";

const NotificationDetail = () => {
  const location = useLocation();

  useEffect(() => {
    seen_notification(location?.state?.notification_ID);
  }, []);
  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <BreadCrumName
        current={"Notification Detail"}
        titlelist={[
          { name: "Home", to: "/home" }, //
          { name: "Notifications", to: "/all-notifications" },
        ]}
      />
      <Container
        w={"full"}
        maxW={"container.lg"}
        alignItems={"center"}
        justifyContent={"center"}
        mt={5}
        p={0}
      >
        <Flex justify={"space-between"}>
          <Heading
            fontSize={["sm", "md", "lg", "xl"]}
            fontWeight={"normal"}
            mb={2}
          >
            {location?.state?.title}
          </Heading>
          <Text
            mb={0}
            fontSize={["xs", "sm"]}
            fontWeight={"medium"}
            color={"#000"}
          >
            {/* {Date.parse(location.state.send_at)
              ? moment(location.state.send_at).fromNow()
              : moment(location.state.send_at).fromNow()} */}
            {moment(location?.state?.send_at).format("MMMM Do YYYY, h:mm:ss a")}
          </Text>
        </Flex>

        {/* {location?.state?.image?.length != 0 && (
          <Center>
            <Image
              src={location.state.image}
              w={"auto"}
              objectFit={"contain"}
              h={["150px", "250px", "300px", "sm"]}
              borderRadius={"md"}
              my={4}
            />
          </Center>
        )} */}
        <Text fontSize={["sm", "md", "lg"]}>{location?.state?.message}</Text>
      </Container>
    </Container>
  );
};

export default NotificationDetail;
