import React, { useEffect, useState } from "react";
import { base_url } from "../../utils/apis";
import { useLocation } from "react-router-dom";
import { Center, Container, Td, Text, Tr } from "@chakra-ui/react";
import BreadCrumName from "../../components/BreadCrumName";
import TableView from "../../components/TableView";
import Loader from "../../components/Loader";
import moment from "moment";

const RechargeHistory = () => {
  const { state } = useLocation();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const get_history = async () => {
    try {
      setLoading(true);
      const responce = await fetch(
        base_url + `recharge/list/user_id/${user?.consumer?.user_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user?.consumer?.token}`,
          },
        }
      );
      const res = await responce.json();
      console.log(res);
      setLoading(false);
      if (res.success) {
        setList(res.list);
      } else {
        setList([]);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    get_history();
  }, []);

  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <BreadCrumName
        current={"Transaction History"}
        titlelist={[
          { name: "Home", to: "/home" },
          { name: "Profile", to: "/profile" },
        ]}
      />
      {loading ? (
        <Loader />
      ) : list?.length == 0 ? (
        <Center p={10}>
          <Text fontSize={20} fontWeight={"600"} color={"#000"}>
            No History Found
          </Text>
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No",
            "Full Name",
            "Email ID",
            "City",
            "Mobile Number",
            "Pincode",
            "GST Number",
            "Date",
          ]}
          body={list?.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={"center"}>{index + 1}</Td>
              <Td textAlign={"center"}>{item.FullName}</Td>
              <Td textAlign={"center"}>{item.Email_ID}</Td>
              <Td textAlign={"center"}>{item.City}</Td>
              <Td textAlign={"center"}>{item.Mobile_Number}</Td>
              <Td textAlign={"center"}>{item.Pincode}</Td>
              <Td textAlign={"center"}>{item.GST_Number}</Td>
              <Td textAlign={"center"}>
                {moment(item.created_at).format("DD/MM/YYYY")}
              </Td>
            </Tr>
          ))}
        />
      )}
    </Container>
  );
};

export default RechargeHistory;
