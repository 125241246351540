import {
  Card,
  Center,
  Container,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import CustomButton1 from "../components/CustomButton1";
import { emailRegex, passwordRegex } from "../utils/utils";
import { register, send_otp } from "../utils/apis";
import { toast } from "react-toastify";
import logoImg from "../assets/logo.png";
import { BackIcon } from "../components/Back/Back";

const encryptedData = "44b68a931c36be4bbd5ea86f3024d9a0";
const iv = "06053f8810aea201bec59ed5a2f7718c";
const securityKey = "12345678901234567890123456789012"; // Replace with your actual security key

const Sigup = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const navigate = useNavigate();

  const [password, setPassword] = useState({
    value: "",
    validate: false,
    mess: "",
  });
  const [firstName, setFirstName] = useState({
    value: "",
    validate: false,
    mess: "",
  });
  const [lastName, setLastName] = useState({
    value: "",
    validate: false,
    mess: "",
  });
  const [mobile, setMobile] = useState({
    value: "",
    validate: false,
    mess: "",
  });
  const [email, setEmail] = useState({ value: "", validate: false, mess: "" });
  const [show_otp, setShow_otp] = useState(false);
  const [c_password, setC_password] = useState({
    value: "",
    validate: false,
    mess: "",
  });
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const [show1, setShow1] = React.useState(false);
  const [otp, setOtp] = useState("");
  const [confOtp, setConfOtp] = useState("");
  const handleClick = () => setShow(!show);
  const handleClick1 = () => setShow1(!show1);
  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }
  const validate = () => {
    if (
      firstName?.value?.length < 3 ||
      lastName?.value?.length < 3 ||
      mobile?.value?.length !== 10 ||
      !emailRegex.test(email?.value) ||
      !passwordRegex.test(password?.value) ||
      password?.value != c_password?.value ||
      !otp?.length ||
      otp?.length != 6 ||
      otp != confOtp
    ) {
      if (firstName?.value?.length < 3) {
        setFirstName({
          ...firstName,
          mess: "First Name is required and should be at least 3 characters long",
        });
      }
      if (lastName?.value?.length < 3) {
        setLastName({
          ...lastName,
          mess: "Last Name is required and should be at least 3 characters long",
        });
      }

      if (mobile?.value?.length !== 10) {
        setMobile({ ...mobile, mess: "Mobile Number must be 10 digits long" });
      }

      if (!emailRegex.test(email?.value)) {
        setEmail({ ...email, mess: "Invalid Email address" });
      }

      if (!passwordRegex.test(password?.value)) {
        setPassword({
          ...password,
          mess: "Password must be at least 8 characters long, contain at least one special character, and one uppercase letter",
        });
      }
      if (password?.value != c_password?.value) {
        setC_password({
          ...c_password,
          mess: "Oops! Password miss-matched",
        });
      }
      if (otp?.length == 0) {
        toast.error("OTP is Required", {
          toastId: "1",
          autoClose: 2000,
        });
      } else if (otp?.length != 6) {
        toast.error("OTP must be 6 digits long", {
          toastId: "1",
          autoClose: 2000,
        });
      } else if (+otp != confOtp) {
        toast.error("Invalid Otp!", {
          toastId: "1",
          autoClose: 2000,
        });
      }
      return;
    }
    const body = {
      first_name: firstName?.value,
      last_name: lastName?.value,
      email_address: email?.value,
      mobile: mobile?.value,
      password: password?.value,
    };
    register(body, setLoading).then((v) => {
      if (v?.success) {
        navigate("/login");
      }
    });
  };

  const get_otp = () => {
    if (!emailRegex.test(email?.value)) {
      setEmail({
        ...email,
        validate: true,
        mess: "Please enter valid email",
      });
      return;
    }

    send_otp(email?.value, setLoading).then((v) => {
      if (v.success) {
        toast.success(v.message, {
          toastId: "1",
          autoClose: 2000,
        });
        // setId(v.id);
        setShow_otp(true);
        // const otp =decryptData(v?.otpCode,"12345678901234567890123456789012");
        setConfOtp(v?.otp);
        // setDisBtn(true);
      } else {
        toast.error(v.message, {
          toastId: "1",
          autoClose: 2000,
        });
        setShow_otp(false);
      }
    });
  };

  // const checkKeyPress = useCallback(
  //   (e) => {
  //     const { key, keyCode } = e;

  //     if (keyCode === 13) {
  //       validate();
  //     }
  //   },
  //   [firstName, lastName, email, password, c_password, mobile]
  // );
  useEffect(() => {
    user !== null && navigate("/home"); //
  }, []);
  // useEffect(() => {
  //   window.addEventListener("keydown", checkKeyPress);
  //   return () => {
  //     window.removeEventListener("keydown", checkKeyPress);
  //   };
  // }, [checkKeyPress]);

  return (
    <Container
      w={"full"}
      h={"100vh"}
      maxW={"full"}
      bg={
        "linear-gradient( 109.6deg, rgba(156,252,248,1) 11.2%, rgba(110,123,251,1) 91.1% )"
      }
      justifyContent={"center"}
      alignItems={"center"}
      display={"flex"}
    >
      <VStack
        h={"full"}
        w={"full"}
        justify={"center"}
        align={"center"}
        display={["none", "none", "none", "flex"]}
      ></VStack>
      <VStack h={"full"} w={"full"} justify={"center"} align={"center"}>
        <Card px={5} w={["full", "lg"]} justify={"center"} py={10}>
          <BackIcon />
          <Center>
            <Image
              src={logoImg}
              // h={"3rem"}
              w={"120px"}
              borderRadius={3}
              p={1}
            />
          </Center>
          <Text fontSize={"lg"} textAlign={"center"} mt={2}>
            Register
          </Text>

          <CustomInput
            placeholder="First Name"
            value={firstName?.value}
            onChange={(e) =>
              setFirstName({
                ...firstName,
                value: capitalizeName(
                  e.target.value
                    .trimStart()
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .replace(/^[0-9]/, "")
                    .replace(/\s{2,}/g, " ")
                ),
                validate: false,
                mess: "",
              })
            }
            isInvalid={firstName?.validate}
            error={firstName?.mess}
          />
          <CustomInput
            placeholder="Last Name"
            value={lastName.value}
            onChange={(e) =>
              setLastName({
                ...lastName,
                value: capitalizeName(
                  e.target.value
                    .trimStart()
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .replace(/^[0-9]/, "")
                    .replace(/\s{2,}/g, " ")
                ),
                validate: false,
                mess: "",
              })
            }
            isInvalid={lastName?.validate}
            error={lastName?.mess}
          />
          <CustomInput
            placeholder="Mobile Number"
            value={mobile?.value}
            onChange={(e) => {
              e.target.value?.length < 11 &&
                setMobile({
                  ...mobile,
                  value: e.target.value.trim().replace(/\D/g, ""), // v.replace(/[^0-9]/g, ''),,
                  validate: false,
                  mess: "",
                });
            }}
            isInvalid={mobile?.validate}
            error={mobile?.mess}
          />
          <CustomInput
            placeholder="Email ID"
            value={email?.value}
            autoCapitalize={false}
            onChange={(e) => {
              setEmail({
                ...email,
                value: e.target.value.trim().toLowerCase(),
                validate: false,
                mess: "",
              });
              setShow_otp(false);
              setConfOtp("");
            }}
            isInvalid={email?.validate}
            error={email?.mess}
            rightButton={
              <Center mt={1}>
                <CustomButton1
                  title={show_otp ? "Resend OTP" : "Get OTP"}
                  loading={loading == 1 ? true : false}
                  onClick={() => get_otp()}
                  // disabled={disBtn}
                />
              </Center>
            }
          />
          {show_otp && (
            <HStack justify={"center"} mt={3}>
              <PinInput placeholder="-" autoFocus value={otp} onChange={setOtp}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          )}

          <CustomInput
            placeholder="Password"
            type={show ? "text" : "password"}
            value={password?.value}
            onChange={(e) =>
              setPassword({
                ...password,
                value: e.target.value.trim(),
                validate: false,
                mess: "",
              })
            }
            isInvalid={password?.validate}
            error={password?.mess}
            right={
              !show ? (
                <FiEye cursor={"pointer"} size={18} onClick={handleClick} />
              ) : (
                <FiEyeOff cursor={"pointer"} size={18} onClick={handleClick} />
              )
            }
          />
          <CustomInput
            placeholder="Confirm Password"
            type={show1 ? "text" : "password"}
            value={c_password?.value}
            onChange={(e) =>
              setC_password({
                ...c_password,
                value: e.target.value.trim(),
                validate: false,
                mess: "",
              })
            }
            isInvalid={c_password?.validate}
            error={c_password?.mess}
            right={
              !show1 ? (
                <FiEye cursor={"pointer"} size={18} onClick={handleClick1} />
              ) : (
                <FiEyeOff cursor={"pointer"} size={18} onClick={handleClick1} />
              )
            }
          />
          <Center mt={4}>
            <CustomButton1
              title={"Register"}
              loading={loading == 5 ? true : false}
              onClick={() => validate()}
            />
          </Center>
          <Link
            to={"/login"}
            style={{
              textAlign: "center",
              marginTop: 10,
              fontSize: "16px",
              color: "blue",
              alignSelf: "center",
            }}
          >
            Already have an account?
          </Link>
        </Card>
      </VStack>
    </Container>
  );
};

export default Sigup;
