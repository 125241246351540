import React from "react";
import { FooterData, HeaderData } from "./MainPage/MainPage";
import "./MainPage/mainpage.css";

const PrivacyPolicy = () => {
  return (
    <>
      <HeaderData />
      <div id="static_privacy_page" className="page-container">
        <div
          className="fixed-width"
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          <h1
            className="page-title"
            style={{ fontSize: "24px", fontWeight: "bold" }}
          >
            Refund Policy
          </h1>

          <div
            className="fixed-width"
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "1rem",
            }}
          >
            <h3
              style={{
                fontSize: "18px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Thank you for choosing SmartDocs365. Please read this policy
              carefully to understand our practices regarding refunds.
            </h3>
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              1. Refund Eligibility
            </h3>
            <p>
              1.1. Service-Specific Terms: Refund eligibility may vary depending
              on the specific service or product you have purchased.
            </p>
            <p>
              1.2. Timeframe: Refund requests must be submitted within 2 days
              from the date of purchase of our services.
            </p>
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              2. Non-Refundable Services
            </h3>
            <p>
              2.1. Customized Services: Services that involve customization or
              personalization may be non-refundable once the customization
              process has begun.
            </p>
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              3. Refund Process
            </h3>
            <p>
              3.1. Submission of Refund Request: To request a refund, please
              contact our customer support team at support@smartdocs365.com.
              Clearly state the reason for your refund request and provide
              relevant details.
            </p>
            <p>
              3.2. Review and Approval: Each refund request will be reviewed on
              a case-by-case basis. We reserve the right to approve or deny
              refund requests based on the specific circumstances. If our
              services are used fully or partially then no Refund will be
              issued.
            </p>
            <p>
              3.3. Refund Method: Approved refunds will be processed using the
              original payment method. Please allow 30 days for the refund to be
              reflected in your account.
            </p>
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              4. Exceptions
            </h3>
            <p>
              4.1. Force Majeure: We are not liable for refunds in cases of
              force majeure events, including but not limited to natural
              disasters, acts of war, or other unforeseeable circumstances
              beyond our control.
            </p>
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              5. Contact Us
            </h3>
            <p>
              If you have any questions, concerns, or requests regarding our
              Refund Policy, please contact us at{" "}
              <b>support@SmartDocs365.com</b> By using our services, you agree
              to abide by the terms outlined in this Refund Policy. Thank you
              for choosing SmartDocs365!
            </p>
          </div>
          <br />
          <br />
        </div>
      </div>
      <FooterData />
    </>
  );
};

export default PrivacyPolicy;
