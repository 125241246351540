import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import CustomButton1 from "./CustomButton1";

const CustomPopup = ({
  isOpen,
  onClose,
  onClick,
  title,
  mess,
  b_name,
  single_button,
  b_color,
  loading,
}) => {
  const cancelRef = React.useRef();
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{mess}</AlertDialogBody>
          <AlertDialogFooter>
            {single_button ? (
              <CustomButton1
                bg={"gray"}
                title={"Cancel"}
                // ref={cancelRef}
                onClick={onClose}
              />
            ) : (
              <Flex gap={3}>
                <CustomButton1
                  bg={"gray"}
                  title={"Cancel"}
                  // ref={cancelRef}
                  onClick={onClose}
                />
                <CustomButton1
                  bg={b_color || "red"}
                  title={b_name || "Delete"}
                  loading={loading}
                  onClick={onClick}
                />
              </Flex>
            )}
          </AlertDialogFooter>
          {/* <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={onClick} ml={3}>
              {b_name || "Delete"}
            </Button>
          </AlertDialogFooter> */}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CustomPopup;
