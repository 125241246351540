import {
  Center,
  Container,
  Flex,
  Image,
  Input,
  Link,
  Spacer,
  Stack,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import BreadCrumName from "../../components/BreadCrumName";
import CustomInput from "../../components/CustomInput";
import CustomButton1 from "../../components/CustomButton1";
import SelectImage from "../../components/SelectImage";
import { useNavigate } from "react-router-dom";
import {
  base_url,
  download_excel_file_api,
  excel_upload_api,
  pdf_update_api,
  pdf_upload_api,
} from "../../utils/apis";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TableView from "../../components/TableView";
import { FiTrash2 } from "react-icons/fi";
import LoaderFileUpload from "../../components/Loader/LoaderFile";
import { Tooltip } from "antd";
import { token } from "../../redux/slice/userSlice";
import { ScaleLoader } from "react-spinners";
import uploadingimg from "../../assets/downloading.gif";
import moment from "moment";
const XLSX = require("xlsx");

const Addpolicy = () => {
  const { other_policy_list } = useSelector((state) => state.policy);
  const navigate = useNavigate();
  const [p_data, setP_data] = useState({});
  const [pdf, setPdf] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [Obj, setObj] = useState([]);
  const [edit, setEdit] = useState(false);
  const [UploadExcel, setUploadExcel] = useState(false);
  const [progress, setProgress] = useState(0);

  const add_policy = async () => {
    try {
      setShowLoader(true);
      for (var i = 0; i < Obj.length; i++) {
        const body = {
          document_id: Obj[i]?.document_id,
          file_details: Obj[i]?.file_details,
          remark: Obj[i].remark || "",
        };
        const response = await pdf_update_api(body);
        if (response.success) {
          toast(response.message, {
            toastId: "1",
          });
        }
      }
      navigate("/home");
    } catch (error) {
      console.log(error);
    }
    setShowLoader(false);
  };

  const policies_list = [];
  for (let i = 0; i < other_policy_list.length; i++) {
    const element = {
      value: other_policy_list[i].policyname,
      label: other_policy_list[i].policyname,
    };
    policies_list.push(element);
  }

  const upload_excel_doc = async (files) => {
    setShowLoader(true);
    try {
      const body = new FormData();
      body.append("excelFile", files[0]);
      const response = await excel_upload_api(body);
      setShowLoader(false);
      if (response.success) {
        toast.success(response.message, {
          toastId: "1",
        });
        navigate("/home");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
    setShowLoader(false);
  };

  const upload_doc = async (files) => {
    try {
      setObj([]);
      setShowLoader(true);
      var formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i].name);
      }
      let request = new XMLHttpRequest();
      request.upload.addEventListener("progress", ProgressHandler, false);
      request.addEventListener("load", SuccessHandler, false);
      request.addEventListener("error", ErrorHandler, false);
      request.addEventListener("abort", AbortHandler, false);
      request.open("POST", `${base_url}upload-pdf/`);
      request.setRequestHeader("Authorization", "Bearer " + token);
      request.send(formData);
      request.addEventListener("load", async function (e) {
        setShowLoader(false);
        const jdata = await JSON.parse(request.response);
        if (jdata?.success) {
          setObj(jdata?.data);
          setProgress(0);
          toast.success(jdata.message, {
            toastId: "1",
          });
        } else {
          toast.error(jdata.message, {
            toastId: "1",
          });
          setProgress(0);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const ProgressHandler = (e) => {
    var percent = (e.loaded / e.total) * 100;
    const data = `${percent.toFixed(0)}`;
    setProgress(data);
    // setProgress(percent.toFixed(0) + "%");
  };

  const SuccessHandler = (e) => {
    setShowLoader(false);
  };
  const ErrorHandler = () => {
    setShowLoader(false);
  };
  const AbortHandler = () => {
    // statusRef.current.innerHTML = "upload aborted!!";
  };

  const delete_item = (id) => {
    const filterData = Obj?.filter((v, i) => v?.document_id != id);
    setObj(filterData);
  };

  const download_excel_formate = async () => {
    try {
      const response = await download_excel_file_api();
    } catch (error) {
      console.error("Error fetching or processing Excel content:", error);
      throw error;
    }
  };
  const override = {
    display: "block",
    margin: "0 auto",
  };

  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <Flex style={{ justifyContent: "space-between" }}>
        <BreadCrumName
          current={"Add Policy"}
          titlelist={[{ name: "Home", to: "/home" }]}
        />
        <Flex style={{ gap: "0.5rem" }}>
          {!UploadExcel && (
            <CustomButton1
              title={"Download Excel (Demo)"}
              onClick={() => download_excel_formate()}
            />
          )}
          <CustomButton1
            title={UploadExcel ? "Upload PDF" : "Upload Excel"}
            onClick={() => setUploadExcel(!UploadExcel)}
          />
        </Flex>
      </Flex>
      <Container
        w={"full"}
        maxW={"md"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Stack flexDirection={"column"} maxW={"md"} alignSelf={"center"}>
          <Stack pt={"4"}>
            <>
              <SelectImage
                doctype={UploadExcel ? ".xls,.xlsx" : ".pdf"}
                label={"Upload policy copy*"}
                mt={"10px"}
                disabled={showLoader || Obj?.length > 0 ? true : false}
                filename={p_data?.img?.name}
                type={p_data?.img?.type}
                url={p_data?.img && pdf}
                multiple={UploadExcel ? false : true}
                onChange={(e) => {
                  const files = e.target.files;
                  if (!UploadExcel) {
                    upload_doc(files);
                  } else if (UploadExcel) {
                    upload_excel_doc(files);
                  }
                }}
              />
            </>
          </Stack>
          <Text color={"blue"} pt={"4"}>
            {UploadExcel
              ? "Please Upload Excel file"
              : "Please Upload .pdf file"}
          </Text>
        </Stack>
      </Container>
      {Obj.length > 0 ? (
        <>
          <TableView
            headData={[
              "Insurance Company Name",
              "Insurance Plan Name",
              "Insurance Policy Type",
              "Insurance Policy Number",
              "Vehicle Registration Number",
              "Engine Number",
              "Chassis Number",
              "Policyholder Name",
              "Policyholder Address",
              "Policyholder Phone Number",
              "Policyholder Emailid",
              "Intermediary Code",
              "Intermediary Name",
              "Intermediary Phone Number",
              "Intermediary Emailid",
              "Total Premium Paid",
              "Own Damage Premium",
              "Base Premium",
              "Policy Start Date",
              "Policy Expiry Date",
              "Policy Issuance Date",
              "Remark",
              "Actions",
            ]}
            body={Obj.map((v, ind) => (
              <Tr key={ind}>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Enter Policy holder name"}
                    value={v?.file_details.Insurance_company_name}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Insurance_company_name: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Insurance plan name"}
                    value={v?.file_details.Insurance_plan_name}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Insurance_plan_name: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Insurance policy type"}
                    value={v?.file_details.Insurance_policy_type}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Insurance_policy_type: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Insurance policy number"}
                    value={v?.file_details.Insurance_policy_number}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Insurance_policy_number: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Vehicle registration number"}
                    value={v?.file_details.Vehicle_registration_number}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Vehicle_registration_number: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Engine number"}
                    value={v?.file_details.Engine_number}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Engine_number: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Chassis number"}
                    value={v?.file_details.Chassis_number}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Chassis_number: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Policyholder name"}
                    value={v?.file_details.Policyholder_name}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Policyholder_name: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Policyholder address"}
                    value={v?.file_details.Policyholder_address}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Policyholder_address: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Policyholder phone number"}
                    value={v?.file_details.Policyholder_phone_number}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Policyholder_phone_number: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Policyholder emailid"}
                    value={v?.file_details.Policyholder_emailid}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Policyholder_emailid: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Intermediary code"}
                    value={v?.file_details.Intermediary_code}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Intermediary_code: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Intermediary name"}
                    value={v?.file_details.Intermediary_name}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Intermediary_name: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Intermediary phone number"}
                    value={v?.file_details.Intermediary_phone_number}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Intermediary_phone_number: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Intermediary emailid"}
                    value={v?.file_details.Intermediary_emailid}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Intermediary_emailid: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Total premium paid"}
                    value={v?.file_details.Total_premium_paid}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Total_premium_paid: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Own damage premium"}
                    value={v?.file_details.Own_damage_premium}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Own_damage_premium: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Base premium"}
                    value={v?.file_details.Base_premium}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                file_details: {
                                  ...item.file_details,
                                  Base_premium: e.target.value,
                                },
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    type={!edit ? "text" : "date"}
                    placeholder={"Policy start date"}
                    value={
                      !edit
                        ? v?.file_details.Policy_start_date
                        : moment(
                            v?.file_details.Policy_start_date,
                            "DD/MM/YYYY"
                          ).format("YYYY-MM-DD")
                    }
                    onChange={(e) => {
                      if (e.target.value.length == 10) {
                        setObj((prevObj) =>
                          prevObj.map((item, i) =>
                            i === ind
                              ? {
                                  ...item,
                                  file_details: {
                                    ...item.file_details,
                                    Policy_start_date: moment(
                                      e.target.value
                                    ).format("DD/MM/YYYY"),
                                  },
                                }
                              : item
                          )
                        );
                      }
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    type={!edit ? "text" : "date"}
                    placeholder={"Policy expiry date"}
                    value={
                      !edit
                        ? v?.file_details.Policy_expiry_date
                        : moment(
                            v?.file_details.Policy_expiry_date,
                            "DD/MM/YYYY"
                          ).format("YYYY-MM-DD")
                    }
                    onChange={(e) => {
                      if (e.target.value.length == 10) {
                        setObj((prevObj) =>
                          prevObj.map((item, i) =>
                            i === ind
                              ? {
                                  ...item,
                                  file_details: {
                                    ...item.file_details,
                                    Policy_expiry_date: moment(
                                      e.target.value
                                    ).format("DD/MM/YYYY"),
                                  },
                                }
                              : item
                          )
                        );
                      }
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    readOnly={!edit}
                    placeholder={"Policy_issuance_date"}
                    type={!edit ? "text" : "date"}
                    value={
                      !edit
                        ? v?.file_details.Policy_issuance_date
                        : moment(
                            v?.file_details.Policy_issuance_date,
                            "DD/MM/YYYY"
                          ).format("YYYY-MM-DD")
                    }
                    onChange={(e) => {
                      if (e.target.value.length == 10) {
                        setObj((prevObj) =>
                          prevObj.map((item, i) =>
                            i === ind
                              ? {
                                  ...item,
                                  file_details: {
                                    ...item.file_details,
                                    Policy_issuance_date: moment(
                                      e.target.value
                                    ).format("DD/MM/YYYY"),
                                  },
                                }
                              : item
                          )
                        );
                      }
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  <CustomInput
                    width={"800px"}
                    readOnly={!edit}
                    placeholder={"Remark"}
                    value={v?.remark}
                    onChange={(e) => {
                      setObj((prevObj) =>
                        prevObj.map((item, i) =>
                          i === ind
                            ? {
                                ...item,
                                remark: e.target.value,
                              }
                            : item
                        )
                      );
                    }}
                  />
                </Td>
                <Td textAlign={"center"}>
                  {
                    <Flex align={"center"} gap={2} justify={"center"}>
                      <Tooltip title={"Remove Policy"}>
                        <FiTrash2
                          size={20}
                          cursor={"pointer"}
                          onClick={() => delete_item(v?.document_id)}
                        />
                      </Tooltip>
                    </Flex>
                  }
                </Td>
              </Tr>
            ))}
          />
          <Container mt={10} width={"100%"} justifyContent={"center"}>
            <Center gap={3}>
              <CustomButton1
                title={"Update"}
                onClick={add_policy}
                bg={"blue"}
              />
              <CustomButton1 title={"Edit"} onClick={() => setEdit(!edit)} />
            </Center>
          </Container>
        </>
      ) : showLoader ? (
        progress == 100 ? (
          <Container
            w={"full"}
            maxW={"md"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
            mt={"2rem"}
            // backgroundColor={"red"}
          >
            <img
              src={uploadingimg}
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
            />
          </Container>
        ) : (
          <Container
            w={"full"}
            maxW={"md"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
            mt={"2rem"}
          >
            <div
              style={{
                width: "250px",
                position: "relative",
                display: "flex",
                backgroundColor: "white",
                height: "15px",
                borderRadius: "10px",
                border: "1px solid blue",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  width: `${progress}%`,
                  transition: "width 1s ease-in",
                  backgroundColor: "blue",
                  borderRadius: "10px",
                }}
              ></div>
            </div>
            <div style={{ color: "blue", fontSize: "16px" }}>
              {progress == 0 ? "0%" : `${progress}%`}
            </div>
          </Container>
        )
      ) : (
        ""
      )}
    </Container>
  );
};

export default Addpolicy;
