import { Flex, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  return (
    <Flex
      bg={"#727b82"}
      maxW={"full"}
      h={["10", "12", "16"]}
      minH={["10", "12", "16"]}
      align={"center"}
      px={"20"}
      justify={"center"}
      mb={0}
    >
      <Heading fontSize={["sm", "md", "lg", "xl"]} color={"#fff"} mb={0}>
        © 2023 SmartDocs365
      </Heading>
    </Flex>
  );
};

export default Footer;
