import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  return user === null ? <Navigate to="/home" replace /> : <Outlet />;
};

export default ProtectedRoute;
