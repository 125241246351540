import { Container, Spacer } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Policy from "./Policy";
import store from "../../redux/Store";
import { getPolicy_list } from "../../redux/slice/policySlice";
import { PriceCard } from "../../components/PriceCard/PriceCard";
import { Bloags } from "../../components/Bloags/Bloags";
import { getUserData } from "../../redux/slice/userSlice";
import { base_url } from "../../utils/apis";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const token = user?.consumer?.token;
  const navigate = useNavigate();

  useEffect(() => {
    store.dispatch(getPolicy_list(token));
    store.dispatch(getUserData(token));
  }, []);
  const check_status = async () => {
    try {
      const responce = await fetch(
        base_url + `update/check-active-account-status`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = await responce.json();
      if (res.success) {
        if (res.blocked) {
          localStorage.removeItem("user");
          localStorage.clear();
          navigate("/");
          window.location.reload();
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    const interval = setInterval(() => check_status(), 8000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Container maxW={"full"} pt={[2, 5]}>
      <Policy />
      <Spacer h={7} />
      <PriceCard />
      <Spacer h={7} />
      <Bloags />
    </Container>
  );
};

export default Home;
