import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Select,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomButton1 from "../../components/CustomButton1";
import { useNavigate } from "react-router-dom";
import { getPolicy_list } from "../../redux/slice/policySlice";
import TableView from "../../components/TableView";
import { FiEye, FiFilter, FiTrash2 } from "react-icons/fi";
import { BiEditAlt } from "react-icons/bi";
import CustomPopup from "../../components/CustomPopup";
import { delete_pdf_api } from "../../utils/apis";
import CustomInput from "../../components/CustomInput";
import Loader from "../../components/Loader";
import moment from "moment";
import ShareOptions from "../../components/ShareOptions";
import store from "../../redux/Store";
import { toast } from "react-toastify";
import { LoaderFull } from "../../components/Loader/LoaderFull";
import { saveAs } from "file-saver";
import { Modal, Tooltip } from "antd";
import { EditPolicy, ShowPolicy } from "../../components/ShowPolicy/ShowPolicy";
import { Pagination } from "../../components/Pagination/Pagination";
import { AiOutlineFilePdf } from "react-icons/ai";
import { RiFileExcel2Line } from "react-icons/ri";
import { parseDateWithFormats } from "../../utils/utils";
export const upload_url = "https://api.smartdocs365.com/";

const Policy = () => {
  const navigate = useNavigate();
  const {
    isOpen: isLOpen,
    onOpen: onLOpen,
    onClose: onLClose,
  } = useDisclosure();
  const { policy_list, p_loading } = useSelector((state) => state.policy);
  const [showLoader, setShowLoader] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [editShowItem, setEditShowItem] = useState({});
  const [ShowEditPolicy, setShowEditPolicy] = useState(false);
  const [entries, setEntries] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const [SerchInput, setSearchInput] = useState("");
  const [SCount, setSCount] = useState(0);
  const [ECount, setECount] = useState(5);
  const [documentid, setDocumentid] = useState("");
  const [sDate, setSDate] = useState("");
  const [eDate, setEDate] = useState("");
  const [filter, setFilter] = useState("");
  const [deleteId, setDeleteId] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const data12 = ["START", "EXPIRY", "ISSUANCE"];
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const token = user?.consumer?.token;
  const [filteredData, setFilteredData] = useState([]);
  const [applied, setApplied] = useState(false);

  useEffect(() => {
    store
      .dispatch(getPolicy_list(user?.consumer?.token))
      .unwrap()
      .then((v) => setFilteredData(v?.data));
  }, []);
  useEffect(() => {
    store
      .dispatch(getPolicy_list(user?.consumer?.token))
      .unwrap()
      .then((v) => setFilteredData(v?.data));
  }, [ShowEditPolicy]);

  const handlePrev = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
      const StartCount = Number(pageNo - 1) * Number(entries) - Number(entries);
      setSCount(StartCount);
      const EndCount = Number(pageNo - 1) * Number(entries);
      setECount(EndCount);
    }
  };
  const handleNext = () => {
    const totalPages = Math.ceil(filteredData?.length / entries);
    if (pageNo < totalPages) {
      setPageNo(pageNo + 1);
      const StartCount = Number(pageNo + 1) * Number(entries) - Number(entries);
      setSCount(StartCount);
      const EndCount = Number(pageNo + 1) * Number(entries);
      setECount(EndCount);
    }
  };

  const handlePageClick = (pageNumber) => {
    setPageNo(pageNumber);
    const StartCount = Number(pageNumber) * Number(entries) - Number(entries);
    setSCount(StartCount);
    const EndCount = Number(pageNumber) * Number(entries);
    setECount(EndCount);
  };
  const onEntriesChange = (e) => {
    setSelectedData([]);
    setEntries(e.target.value);
    setPageNo(1);
    const StartCount =
      Number(1) * Number(e.target.value) - Number(e.target.value);
    setSCount(StartCount);
    const EndCount = Number(1) * Number(e.target.value);
    setECount(EndCount);
  };
  const onSearchChange = (e) => {
    setSearchInput(e);
    setFilteredData(
      policy_list.filter((item) =>
        Object.values(item.file_details).some((value) =>
          String(value).toLowerCase().includes(e.toLowerCase().trim())
        )
      )
    );
  };
  const onResetFilter = () => {
    setApplied(false);
    setFilter("");
    setSDate("");
    setEDate("");
    store
      .dispatch(getPolicy_list(token))
      .unwrap()
      .then((v) => setFilteredData(v.data));
  };
  function onApplyFilter() {
    if (!filter || !sDate || !eDate) {
      toast.info("Plaese select filter option and date", { toastId: 1 });
      return;
    }
    // Parse start date and end date using Moment.js
    const startDate = moment(
      sDate,
      ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY"],
      true
    );
    const endDate = moment(
      eDate,
      ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY"],
      true
    );

    // If either start date or end date is invalid, return an empty array
    if (!startDate.isValid() || !endDate.isValid()) {
      return [];
    }
    setApplied(true);
    if (filter == "START" || filter == "EXPIRY" || filter == "ISSUANCE") {
      // Filter data between start date and end date
      const filteredData = policy_list.filter((item) => {
        moment(
          item?.file_details?.Policy_issuance_date,
          ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY"],
          true
        );
        const itemDate =
          filter == "START"
            ? moment(
                item?.file_details?.Policy_start_date,
                ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY"],
                true
              )
            : filter == "EXPIRY"
            ? moment(
                item?.file_details?.Policy_expiry_date,
                ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY"],
                true
              )
            : filter == "ISSUANCE"
            ? moment(
                item?.file_details?.Policy_issuance_date,
                ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY"],
                true
              )
            : "";

        return (
          itemDate.isValid() &&
          itemDate.isBetween(startDate, endDate, null, "[]")
        ); // '[]' includes both start and end dates
      });
      setFilteredData(filteredData);
    }
    return;
  }

  const delete_policy = async (id) => {
    try {
      setShowLoader(true);
      const response = await delete_pdf_api(id, token);
      setShowLoader(false);
      if (response.success) {
        store
          .dispatch(getPolicy_list(user?.consumer?.token))
          .unwrap()
          .then((v) => setFilteredData(v.data));
        toast.success(response.message, {
          toastId: "1",
        });
        onLClose();
      } else if (!response.success) {
        toast.error(response.message, {
          toastId: "1",
        });
        onLClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadFile = (path, fileName) => {
    const filePath = upload_url + path;
    var link = document.createElement("a");
    link.href = filePath;
    link.download = `${fileName.split(".")[0]}.pdf`;
    link.target = "_blank"; // Open in a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadExcel = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType: "application/octet-stream",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `${fileName.split(".")[0]}.xlsx`);
  };

  const downloadFullExcel = (data) => {
    if (data?.length > 0) {
      const ws = XLSX.utils.json_to_sheet(data.map((v) => v.file_details));

      const firstRowRange = XLSX.utils.decode_range(ws["!ref"]);
      for (let col = firstRowRange.s.c; col <= firstRowRange.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({
          r: firstRowRange.s.r,
          c: col,
        });
        ws[cellAddress].s = { fill: { fgColor: { rgb: "DDDDDD" } } }; // Gray color
      }

      for (let col = firstRowRange.s.c; col <= firstRowRange.e.c; col++) {
        let maxCellLength = 30;

        for (let row = firstRowRange.s.r + 1; row <= firstRowRange.e.r; row++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          const cellValue = ws[cellAddress] ? ws[cellAddress].w || "" : "";
          maxCellLength = Math.max(maxCellLength, cellValue.length);
        }

        const colWidth = maxCellLength + 1; // You can adjust this value based on your needs
        ws["!cols"] = ws["!cols"] || [];
        ws["!cols"][col] = { width: colWidth };
      }

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
        mimeType: "application/octet-stream",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      console.log(data);

      saveAs(
        blob,
        `${
          data?.length == 1
            ? data[0]?.file_name.split(".")[0]
            : moment(new Date()).format("DD-MMM-YYYY")
        }_file.xlsx`
      );
    } else {
      toast.error("Please Add Some Item", {
        toastId: "1",
        autoClose: 2000,
      });
    }
  };

  function DateComparison(expiryDate) {
    // Get today's date
    const today = new Date();
    // Create a date 30 days ago
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    // Create a date 15 days ago
    const fifteenDaysAgo = new Date();
    fifteenDaysAgo.setDate(today.getDate() - 15);

    // Your date to compare
    const compareDate = new Date(
      moment(expiryDate, "DD/MM/YYYY").format("YYYY-MM-DD")
    ); // Replace this with your date

    // Compare with today's date
    const differenceInDays = (date1, date2) => {
      const diffInTime = date2.getTime() - date1.getTime();
      return diffInTime / (1000 * 3600 * 24);
    };

    const daysDifference = differenceInDays(today, compareDate);

    let message = "";
    if (daysDifference < 0) {
      //  message = "The date is expired";
      message = "#f00";
    } else if (daysDifference < 15) {
      //  message = "The date is less than 15 days ago";
      message = "#FFC000";
    } else if (daysDifference < 30) {
      //  message = "The date is between 15 and 30 days ago";
      message = "#0096FF";
    } else {
      //  message = "The date is more than 30 days ago";
      message = "#000000";
    }
    return message;
  }

  return (
    <Container maxW={"full"}>
      <Flex align={"center"} justify={"space-between"} mb={5}>
        <Heading
          fontSize={["md", "lg", "xl", "2xl"]}
          fontWeight={"medium"}
          color={"#0f0d66"}
        >
          Existing Policies
        </Heading>
        <CustomButton1
          title={"Add a policy"}
          onClick={() => navigate("/add-policy")}
          // onClick={() => navigate("/success")}
        />
      </Flex>
      <Flex
        overflowX={"auto"}
        display={"flex"}
        justify={["start", "center"]}
      ></Flex>
      <>
        <>
          <Pagination
            top={true}
            hideEntries={filteredData?.length == 0 ? true : false}
            totalCount={filteredData?.length}
            SerchInput={SerchInput}
            setSearchInput={setSearchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
            onEntriesChange={(v) => onEntriesChange(v)}
            onSearchChange={(v) => onSearchChange(v)}
          />
        </>

        {/* {filteredData?.length > 0 && ( */}
        <Flex
          style={{
            justifyContent: "flex-end",
            flexWrap: "wrap",
            gap: ".2rem",
          }}
        >
          <Flex
            style={{ gap: "1rem" }}
            justifyContent={"center"}
            flexDirection={["column", "row"]}
          >
            <Flex
              flexDirection={"column"}
              gap={2}
              m={2}
              justifyContent={"center"}
            >
              <Text>Filter</Text>
              <Select
                label={"Filter"}
                icon={<FiFilter />}
                minW={"40"}
                bg={"#fff"}
                value={filter}
                placeholder="Select..."
                onChange={(e) => setFilter(e.target.value)}
              >
                {data12.map((v, i) => (
                  <option key={i} value={v}>
                    {v}
                  </option>
                ))}
              </Select>
            </Flex>
            <CustomInput
              value={moment(sDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
              onChange={(e) => {
                if (e.target.value.length == 10) {
                  setSDate(moment(e.target.value).format("DD/MM/YYYY"));
                }
              }}
              label={"From"}
              // maxW={"170px"}
              // w={"170px"}
              type={"date"}
            />
            <CustomInput
              value={moment(eDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
              onChange={(e) => {
                if (e.target.value.length == 10) {
                  setEDate(moment(e.target.value).format("DD/MM/YYYY"));
                }
              }}
              label={"To"}
              // maxW={"170px"}
              // w={"170px"}
              type={"date"}
            />
          </Flex>
          <Flex
            flexDirection={"column"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            marginBottom={3}
          >
            {/* <CustomButton1
              title={"Download Excel File"}
              onClick={() => downloadFullExcel(filteredData)}
            /> */}
            <Flex
              flexDirection={"row"}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
              display={"flex"}
              gap={2}
            >
              <CustomButton1
                title={"Apply Filter"}
                onClick={() => onApplyFilter()}
              ></CustomButton1>
              {applied && (
                <CustomButton1
                  title={"Reset"}
                  onClick={() => onResetFilter()}
                ></CustomButton1>
              )}
              <CustomButton1
                title={"Download Excel File"}
                onClick={() => downloadFullExcel(filteredData)}
              />
            </Flex>
          </Flex>
        </Flex>
        {/* )} */}

        {p_loading ? (
          <Loader />
        ) : filteredData?.length == 0 ? (
          <Center flexDirection={"column"}>
            <Image
              src={"no-data1.png"}
              w={["200px", "xs", "sm"]}
              objectFit={"contain"}
            />
          </Center>
        ) : (
          <>
            <TableView
              headData={[
                <div>
                  <input
                    type="checkbox"
                    checked={
                      selectedData?.length ==
                      filteredData?.slice(SCount, ECount).length
                        ? true
                        : false
                    }
                    onChange={() => {
                      const arr = [];
                      const mapData =
                        filteredData?.slice(SCount, ECount).length ==
                        selectedData.length
                          ? setSelectedData([])
                          : setSelectedData(
                              filteredData
                                ?.slice(SCount, ECount)
                                .map((v) => v?.document_id)
                            );
                      return mapData;
                    }}
                  />
                </div>,
                "Insurance Company Name",
                "Insurance Plan Name",
                "Insurance Policy Type",
                "Insurance Policy Number",
                "Vehicle Registration Number",
                "Engine Number",
                "Chassis Number",
                "Policyholder Name",
                "Policyholder Address",
                "Policyholder Phone Number",
                "Policyholder Emailid",
                "Intermediary Code",
                "Intermediary Name",
                "Intermediary Phone Number",
                "Intermediary Emailid",
                "Total Premium Paid",
                "Own Damage Premium",
                "Base Premium",
                "Policy Start Date",
                "Policy Expiry Date",
                "Policy Issuance Date",
                "Created At",
                "Created File Name",
                "Remark",
                "Actions",
              ]}
              body={filteredData?.slice(SCount, ECount)?.map((v, ind) => (
                <Tr key={ind}>
                  <Td textAlign={"center"}>
                    <input
                      style={{ cursor: "pointer" }}
                      type="checkbox"
                      checked={selectedData?.includes(v?.document_id)}
                      onChange={() => {
                        const filterDataAdd = selectedData?.filter(
                          (item) => item == v?.document_id
                        );
                        const filterDataRemove = selectedData?.filter(
                          (item) => item != v?.document_id
                        );
                        if (filterDataAdd?.length > 0) {
                          setSelectedData(filterDataRemove);
                        } else {
                          setSelectedData([...selectedData, v?.document_id]);
                        }
                      }}
                    />
                  </Td>

                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Insurance_company_name}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Insurance_plan_name}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Insurance_policy_type}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Insurance_policy_number}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Vehicle_registration_number}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Engine_number}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Chassis_number}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Policyholder_name}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Policyholder_address}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Policyholder_phone_number}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Policyholder_emailid}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Intermediary_code}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Intermediary_name}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Intermediary_phone_number}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Intermediary_emailid}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Total_premium_paid}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Own_damage_premium}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Base_premium}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_details.Policy_start_date == "NA"
                      ? v?.file_details.Policy_start_date
                      : parseDateWithFormats(v?.file_details.Policy_start_date)}
                  </Td>
                  <Td
                    textAlign={"center"}
                    color={DateComparison(v?.file_details.Policy_expiry_date)}
                  >
                    {v?.file_details.Policy_expiry_date == "NA"
                      ? v?.file_details.Policy_expiry_date
                      : parseDateWithFormats(
                          v?.file_details.Policy_expiry_date
                        )}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {/* {v?.file_details.Policy_issuance_date} */}
                    {v?.file_details.Policy_issuance_date == "NA"
                      ? v?.file_details.Policy_issuance_date
                      : parseDateWithFormats(
                          v?.file_details.Policy_issuance_date
                        )}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {moment(v?.created_at).format("DD/MM/YYYY")}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.file_name}
                  </Td>
                  <Td textAlign={"center"} color={"#000"}>
                    {v?.remark}
                  </Td>
                  <Td textAlign={"center"}>
                    {
                      <Flex align={"center"} gap={2} justify={"center"}>
                        <Tooltip title={"View Policy"}>
                          <FiEye
                            size={20}
                            cursor={"pointer"}
                            onClick={() => {
                              setShowPolicy(true);
                              setEditShowItem(v?.file_details);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title={"Edit Policy"}>
                          <BiEditAlt
                            onClick={() => {
                              setShowEditPolicy(true);
                              setEditShowItem(v?.file_details);
                              setDocumentid(v?.document_id);
                            }}
                            size={20}
                            cursor={"pointer"}
                            remarkData={v?.remark}
                          />
                        </Tooltip>
                        <Tooltip title={"Share Policy"}>
                          <ShareOptions
                            mobile={v?.file_details?.Policyholder_phone_number}
                            url={"\nhttps://smartdocs365.com/"}
                            text={Object.keys(v?.file_details)
                              .map(
                                (key) =>
                                  `${key.replace(/_/g, " ")}: ${
                                    v?.file_details[key]
                                  }`
                              )
                              .join("\n")}
                            icon
                          />
                        </Tooltip>
                        <Tooltip title={"Remove Policy"}>
                          <FiTrash2
                            size={20}
                            cursor={"pointer"}
                            onClick={() => {
                              // delete_policy(v?.document_id);
                              setDeleteId(v?.document_id);
                              onLOpen();
                            }}
                          />
                        </Tooltip>
                        <Tooltip title={"Download Excel"}>
                          <RiFileExcel2Line
                            size={20}
                            cursor={"pointer"}
                            onClick={() =>
                              downloadExcel([v.file_details], v?.file_name)
                            }
                          />
                        </Tooltip>
                        <Tooltip title={"Download PDF"}>
                          <AiOutlineFilePdf
                            size={20}
                            cursor={"pointer"}
                            onClick={() =>
                              downloadFile(v.file_path, v?.file_name)
                            }
                          />
                        </Tooltip>
                      </Flex>
                    }
                  </Td>
                </Tr>
              ))}
            />
          </>
        )}
        {filteredData?.length > 5 && (
          <Pagination
            bottom={true}
            totalCount={filteredData?.length}
            SerchInput={SerchInput}
            setSearchInput={setSearchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
            handleNext={handleNext}
            handlePrev={handlePrev}
            handlePageClick={(v) => handlePageClick(v)}
          />
        )}
        {selectedData?.length > 0 && (
          <Flex
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            gap={".5rem"}
            marginTop={"0.5rem"}
          >
            <CustomButton1
              title={"Download Excel (Selected)"}
              onClick={() => {
                const arr = [];
                selectedData?.forEach((selectedItemId) => {
                  filteredData?.slice(SCount, ECount).forEach((listItem) => {
                    if (selectedItemId === listItem?.document_id) {
                      arr.push(listItem);
                    }
                  });
                });
                setSelectedData([]);
                downloadFullExcel(arr);
              }}
            ></CustomButton1>
            <CustomButton1
              title={"Delete Selected"}
              onClick={async () => {
                for (var i = 0; i < selectedData?.length; i++) {
                  await delete_policy(selectedData[i]);
                }
                setSelectedData([]);
              }}
            ></CustomButton1>
          </Flex>
        )}
      </>
      {showPolicy && (
        <Modal
          title={editShowItem?.Policyholder_name}
          open={showPolicy}
          footer={false}
          onCancel={() => setShowPolicy(false)}
          width={"fit-content"}
        >
          <ShowPolicy data={editShowItem} />
        </Modal>
      )}
      {ShowEditPolicy && (
        <Modal
          title={editShowItem?.Policyholder_name}
          open={ShowEditPolicy}
          footer={false}
          onCancel={() => setShowEditPolicy(false)}
          width={"80%"}
        >
          <EditPolicy
            documentId={documentid}
            data={editShowItem}
            setShowEditPolicy={setShowEditPolicy}
          />
        </Modal>
      )}

      <CustomPopup
        isOpen={isLOpen}
        onClick={() => {
          onLClose();
          delete_policy(deleteId);
        }}
        onClose={onLClose}
        title={"Delete"}
        mess={"Are you sure you want to delete"}
        b_name={"Delete"}
      />
      {showLoader && <LoaderFull loading={showLoader} />}
    </Container>
  );
};

export default Policy;
