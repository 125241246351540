import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/apis";
import moment from "moment";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
const token = user?.consumer?.token;

export const getPolicy_list = createAsyncThunk("policy", async () => {
  try {
    const responce = await fetch(`${base_url}pdf/list/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await responce.json();

    return res;
  } catch (error) {
    console.log(error);
  }
});
export const getFilter_Policy_list = createAsyncThunk(
  "filterpolicy",
  async (data) => {
    try {
      const fromDate = moment(data?.sDate).format("YYYY-MM-DD");
      const toDate = moment(data?.eDate).format("YYYY-MM-DD");
      let url =
        base_url + "pdf/list?" + `from_date=${fromDate}&to_date=${toDate}`;
      const responce = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      const res = await responce.json();
      return res;
    } catch (error) {
      console.log(error);
    }
  }
);

// Archived Policy

export const archived_policy_api = createAsyncThunk(
  "archived-policy",
  async (data) => {
    try {
      const fromDate = moment(data?.sDate).format("YYYY/MM/DD");
      const toDate = moment(data?.eDate).format("YYYY/MM/DD");

      // Constructing the base URL
      let url = `${base_url}pdf/expire-policy/list`;

      // Adding the date range parameters if available
      if (data?.success && fromDate && toDate) {
        url += `?from_date=${fromDate}&to_date=${toDate}`;
      }
      const responce = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const res = await responce.json();

      return res;
    } catch (error) {
      console.log(error);
    }
  }
);

const policySlice = createSlice({
  name: "policy",
  initialState: {
    policy_list: [],
    archived_policy_list: [],
    search_policy_list: [],
    policy_provider_list: [],
    other_policy_list: [],
    provider_loading: false,
    other_loading: false,
    p_loading: false,
    s_loading: false,
    a_loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getPolicy_list.pending, (state, action) => {
      state.p_loading = true;
    });
    builder.addCase(getPolicy_list.rejected, (state, action) => {
      state.p_loading = false;
    });
    builder.addCase(getPolicy_list.fulfilled, (state, action) => {
      state.p_loading = false;
      const data = action.payload;
      if (data?.success) {
        state.policy_list = data.data.reverse();
      } else {
        state.policy_list = [];
      }
    });
    builder.addCase(getFilter_Policy_list.pending, (state, action) => {
      state.p_loading = true;
    });
    builder.addCase(getFilter_Policy_list.rejected, (state, action) => {
      state.p_loading = false;
    });
    builder.addCase(getFilter_Policy_list.fulfilled, (state, action) => {
      state.p_loading = false;
      const data = action.payload;
      if (data?.success) {
        state.policy_list = data.data.reverse();
      } else {
        state.policy_list = [];
      }
    });

    builder.addCase(archived_policy_api.pending, (state, action) => {
      state.a_loading = true;
    });
    builder.addCase(archived_policy_api.rejected, (state, action) => {
      state.a_loading = false;
    });
    builder.addCase(archived_policy_api.fulfilled, (state, action) => {
      state.a_loading = false;
      const data = action.payload;
      if (data.success) {
        state.archived_policy_list = data.data.reverse();
      } else {
        state.archived_policy_list = [];
      }
    });
  },
});
export default policySlice.reducer;
