import React, { useEffect, useState } from "react";
import store from "../redux/Store";
import { get_plan_subcription } from "../redux/slice/userSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FooterData, HeaderData } from "./MainPage/MainPage";
import { Spacer } from "@chakra-ui/react";
import PlanCmp from "../components/PlanCmp";

const Plans = () => {
  const [isCheckedYes, setIsCheckedYes] = useState(false);
  const [isCheckedNo, setIsCheckedNo] = useState(false);
  const { plan_list } = useSelector((state) => state.user);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === "cover_yes" && checked) {
      setIsCheckedYes(true);
      setIsCheckedNo(false);
    } else if (name === "cover_no" && checked) {
      setIsCheckedYes(false);
      setIsCheckedNo(true);
    } else {
      setIsCheckedYes(false);
      setIsCheckedNo(false);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    store.dispatch(get_plan_subcription());
  }, []);
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderData />
      <div id="static_pricing_page" className="page-container">
        <div
          className="fixed-width np_01"
          style={{
            flexDirection: "column",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <br />

          <div
            style={{
              color: "black",
              fontSize: "24px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Choose Your Plan
          </div>
        </div>
      </div>

      {/* <div style={{ color: "black", fontSize: "24px", fontWeight: "bold" }}>
        Choose Your Plan
      </div> */}
      <div /* id="plans" */ className="plan_wraper">
        {plan_list?.map((v, i) => (
          <PlanCmp
            planName={v?.plan_name}
            price={v?.plan_price}
            days={v?.plan_duration}
            line1={`Upto ${v?.pdf_limit} pdf for ${v?.plan_duration} days`}
            line2={`Recharge again after ${v?.plan_duration} days or ${v?.pdf_limit} PDF usage whichever is earlier`}
            line3={!v.line1 ? "..........." : v.line1}
            line4={!v.line2 ? "..........." : v.line2}
            line5={!v.line3 ? `.........` : v.line3}
            b_name={"Get Started"}
            onClick={() => {
              if (localStorage.getItem("token")) {
              } else {
                navigate("/login");
              }
            }}
          />
          // <div key={i} className="plan popular" id="plus_plan">
          //   {/* <img
          //     className="banner"
          //     src="/assets/popular_banner-ad719d0ca36bfadd41ae272b8f1db643120266a07f62b8e03da9ae767762f236.png"
          //   /> */}
          //   <h2>{v?.plan_name}</h2>

          //   <div className="pricing">
          //     <span className="dollar">Rs</span>
          //     <span className="price">{v?.plan_price}</span>
          //     <span className="permo">/{v?.plan_duration} days</span>
          //     <div className="discount" style={{ display: "none" }}>
          //       (<span className="discount-percent-off">0</span>% off
          //       Rs1000/mo, paid annually)
          //     </div>
          //   </div>

          //   <div className="inner">
          //     <div className="benefits" style={{ flexDirection: "column" }}>
          //       <div className="benefit">
          //         Upto {v?.pdf_limit} pdf for {v?.plan_duration} days
          //       </div>
          //       <div className="benefit">
          //         Recharge again after {v?.plan_duration} days or{" "}
          //         {v?.pdf_limit} PDF usage whichever is earlier
          //       </div>
          //       <div className="benefit">
          //         {v.line1 ? v.line1 : "File size 10 mb per pdf"}
          //       </div>
          //       <div className="benefit">
          //         {v.line2
          //           ? v.line2
          //           : "Human Loop (Our manual review for accuracy):"}
          //       </div>
          //       <div
          //         className="benefit"
          //         // style={{
          //         //   flex: 1,
          //         //   wordWrap: "break-word",
          //         //   backgroundColor: "red",
          //         //   textOverflow: "ellipsis",
          //         // }}
          //       >
          //         {v.line3 ? v.line3 : `${v?.pdf_limit} extra per month`}
          //       </div>
          //     </div>

          //     <div className="start">
          //       <div
          //         className="button get-started-button"
          //         onClick={() => {
          //           if (localStorage.getItem("token")) {
          //           } else {
          //             navigate("/login");
          //           }
          //         }}
          //       >
          //         Get Started
          //       </div>
          //       <div className="your-plan" style={{ display: "none" }}>
          //         This is your plan.
          //       </div>
          //     </div>
          //   </div>
          // </div>
        ))}

        <div className="clear"></div>
      </div>

      <div className="clear" style={{ height: 20, display: "flex" }}></div>
      {/* </div> */}
      {/* // </div> */}
      <div style={{ flex: 1 }} />
      <FooterData />
    </div>
  );
};

export default Plans;
