import { Flex, Spacer, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

const Layout = () => {
  return (
    // <VStack
    //   h={"100%"}
    //   minH={"100vh"} //
    //   maxW={"full"}
    //   spacing={0}
    //   minW={"100wh"} //
    //   overflowY={"auto"} //
    // >
    //   <Stack
    //     h={"16"}
    //     position={"sticky"}
    //     bg={"#727b82"}
    //     w={"full"}
    //     justify={"center"}
    //   >
    //     <Header />
    //   </Stack>
    //   <Stack
    //     overflowY={"auto"}
    //     w={"full"}
    //     bg={"#f8f9fe"}
    //     h={"100%"}
    //     maxW={"full"}
    //   >
    //     <Outlet />
    //     <Spacer />
    //     <Footer />
    //   </Stack>
    // </VStack>
    <VStack
      h={"100vh"}
      minH={"100vh"}
      maxW={"full"}
      spacing={0}
      minW={"100wh"}
      overflowY={"auto"}
    >
      <Stack
        h={"16"}
        position={"sticky"}
        bg={"#727b82"}
        w={"full"}
        justify={"center"}
      >
        <Header />
      </Stack>
      <Stack
        overflowY={"auto"}
        w={"full"}
        bg={"#f8f9fe"}
        h={"full"}
        maxW={"full"}
      >
        <Outlet />
        <Spacer />
        <Footer />
      </Stack>
    </VStack>
  );
};

export default Layout;
