import React, { useEffect, useRef, useState } from "react";
import "./mainpage.css";
import { useNavigate } from "react-router-dom";
import computerImg from "../../assets/image1.png";
import computerImg21 from "../../assets/image2.jpg";
import computerImg1 from "../../assets/Picture1.png";
import computerImg2 from "../../assets/Picture2.jpg";
import computerImg3 from "../../assets/Picture3.png";
import computerImg4 from "../../assets/Picture4.png";
import logoImg from "../../assets/logo.png";
import { IoMenu } from "react-icons/io5";

export const MainPage = () => {
  const navigate = useNavigate();
  return (
    <div id="whole-np">
      <HeaderData />
      <div id="header_push" className="clear"></div>

      <div id="static_index_page" className="page-container">
        <div id="top_section">
          <div style={{ padding: "0 0 2rem 0" }} className="fixed-width np-2">
            <div id="top_section_image_mobile">
              <img src={computerImg} width="350" />
            </div>
            <div id="top_section_text">
              <h1>AI Powered instant data entry of all your policies</h1>
              <h3 className="top-section-subtext">
                Smartly track your business 365 days in automation mode
              </h3>
              <p
                href="/join"
                onClick={() => navigate("/login")}
                className="button orange"
              >
                Get Started
              </p>
              <p
                id="try_now_button"
                onClick={() => navigate("/singup")}
                className="button white"
              >
                Try Now
              </p>
            </div>
            <div id="top_section_image" className="hide-sm">
              <img
                src={computerImg}
                style={{
                  height: 300,
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
        <div className="clear"></div>

        <div id="benefits_section">
          <div
            className="fixed-width"
            style={{
              flexDirection: "column",
              gap: "1rem",
              padding: "0",
              maxWidth: "100%",
            }}
          >
            <h3 style={{ padding: "0" }}>Benefits</h3>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              className="np-ben"
            >
              <div className="benefit benefit-hide-mobile">
                <div className="benefit-image">
                  <img src={computerImg1} />
                </div>
                <div className="benefit-text">
                  <h4 className="benefit-title">Smart Work</h4>
                  <div className="benefit-description">
                    AI powered instant data entry
                  </div>
                </div>
              </div>

              <div className="benefit">
                <div className="benefit-image">
                  <img src={computerImg2} />
                </div>
                <div className="benefit-text">
                  <h4 className="benefit-title">Reminders</h4>
                  <div className="benefit-description">
                    Send Automatic reminders
                  </div>
                </div>
              </div>
              <div className="benefit benefit-hide-mobile">
                <div className="benefit-image">
                  <img src={computerImg3} />
                </div>
                <div className="benefit-text">
                  <h4 className="benefit-title">Save Time</h4>
                  <div className="benefit-description">
                    Save staff time and salary
                  </div>
                </div>
              </div>
              <div className="benefit benefit-hide-mobile">
                <div className="benefit-image">
                  <img src={computerImg4} />
                </div>
                <div className="benefit-text">
                  <h4 className="benefit-title">Smart Tracking</h4>
                  <div className="benefit-description">
                    Smartly track your business 365 days in automation mode
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="why_section">
          <div className="fixed-width why-this">
            <div id="why_section_part2">
              <img
                style={{ height: "300px", width: "400px" }}
                id="why_section_diagram"
                src={computerImg21}
              />
            </div>
            <div id="why_section_part1">
              <div id="why_section_pretitle">WHY Smartdocs365</div>
              <h2 id="why_section_title">
                Smartdocs365{" "}
                <span className="why-section-text-emphasis">saves 95%</span> of
                your time spent reading docs.
              </h2>
              <div id="why_section_explanation">
                You have too many files to read as is. Stop wasting time.
                Instead, have the Smartdocs365 AI read your files and answer
                your questions. Get ready to save some serious time and impress
                your boss!
              </div>
              <a onClick={() => navigate("/login")} className="button orange">
                Get Started
              </a>
            </div>
          </div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear" id="footer_push"></div>

      <FooterData />
    </div>
  );
};

export const HeaderData = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const position1Ref = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideShowLog);
    document.addEventListener("scroll", handleClickOutsideShowLog);
    return () => {
      document.removeEventListener("click", handleClickOutsideShowLog);
      document.removeEventListener("scroll", handleClickOutsideShowLog);
    };
  }, []);
  const handleClickOutsideShowLog = (event) => {
    if (position1Ref.current && !position1Ref.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  const dropRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setTimeout(() => {
        // setOpen(false);
      }, 150);
    } else {
    }
  };
  return (
    <div id="header" style={{ zIndex: "1" }}>
      <div ref={position1Ref} className="fixed-width">
        <img
          style={{
            cursor: "pointer",
            height: 55,
            width: 55,
            objectFit: "contain",
          }}
          src={logoImg}
          alt="logo"
          onClick={() => {
            navigate("/"); //
          }}
        />

        <div
          id="user_header"
          style={{ position: "relative" }}
          className="user_toggle unknown"
        >
          <div className="signed-in-link-container np-menu">
            <p className="nav-link" onClick={() => navigate("/plans")}>
              Plans
            </p>
            <p className="nav-link" onClick={() => navigate("/contact")}>
              Contact
            </p>
            <p className="nav-link" onClick={() => navigate("/about")}>
              About-Us
            </p>
            <p className="nav-link" onClick={() => navigate("/updates")}>
              Updates
            </p>
            <p
              className="sign-in-button user-header-sign-in nav-link"
              onClick={() => navigate("/login")}
            >
              Login
            </p>
            <p
              className="sign-in-button user-header-sign-in nav-link"
              onClick={() => navigate("/singup")}
            >
              Sign Up
            </p>
          </div>
          <div className="np-menu-new" onClick={() => setShowMenu(!showMenu)}>
            <IoMenu size={35} />
          </div>
        </div>
        {showMenu && (
          <div className="menu-items" style={{ zIndex: "21" }}>
            <div onClick={() => navigate("/plans")}>Plans</div>
            <div onClick={() => navigate("/contact")}>Contact</div>
            <div onClick={() => navigate("/about")}>About us</div>
            <div onClick={() => navigate("/updates")}>Updates</div>
            <div onClick={() => navigate("/login")}>Login</div>
            <div onClick={() => navigate("/singup")}>Sign Up</div>
          </div>
        )}
      </div>

      <div className="clear"></div>
    </div>
  );
};

export const FooterData = () => {
  const navigate = useNavigate();
  return (
    <div id="footer" className="">
      <div className="central fixed-width footer-sd">
        <img
          src={logoImg}
          style={{
            cursor: "pointer",
            height: 90,
            width: 90,
            objectFit: "contain",
            marginBottom: 10,
          }}
          alt="logo"
          onClick={() => {
            navigate("/"); //
          }}
        />

        <div className="left">
          <p>AI powered instant data entry</p>
          <p>© 2024 SmartDocs365. All rights reserved..</p>
          <p style={{ display: "flex", gap: ".5rem" }}>
            {/* <a href="/terms">Terms of Service</a>
            <span>-</span> */}
            <a onClick={() => navigate("/privacy")}>Privacy Policy</a>
            <a onClick={() => navigate("/term-condition")}>Terms & Condition</a>
            <a onClick={() => navigate("/privacy-policy")}>Refund Policy</a>
          </p>
        </div>

        <div className="right">
          <div>
            <h3>Social</h3>
            <p>
              <a
                href="https://www.youtube.com/channel/UCvufWQrw1U0-SdQ1OLlV3sw"
                target="_blank"
              >
                Youtube
              </a>
            </p>
            <p>
              <a href="https://www.linkedin.com/" target="_blank">
                LinkedIn
              </a>
            </p>
            <p>
              <a onClick={() => navigate("/updates")}>Blog</a>
            </p>
          </div>
          <div>
            <h3>More Info</h3>
            <p>
              <a onClick={() => navigate("/about")}>About</a>
            </p>
            <p>
              <a onClick={() => navigate("/contact")}>Contact</a>
            </p>
            <p>
              <a onClick={() => navigate("/plans")}>Plans</a>
            </p>
            <p>
              <a onClick={() => navigate("/updates")}>Updates</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
