import React from "react";
// import Input from './Pagination.css';
import "./Pagination.css";
import { Input } from "antd";
import { Flex, Select } from "@chakra-ui/react";

export const Pagination = ({
  top,
  bottom,
  totalCount,
  pageNo,
  setPageNo,
  entries,
  setEntries,
  SerchInput,
  setSearchInput,
  hideEntries,
  handlePageClick,
  handleNext,
  handlePrev,
  onEntriesChange,
  onSearchChange,
}) => {
  // const handlePrev = () => {
  //   if (pageNo > 1) {
  //     setPageNo(pageNo - 1);
  //   }
  // };
  // const handleNext = () => {
  //   const totalPages = Math.ceil(totalCount / entries);
  //   if (pageNo < totalPages) {
  //     setPageNo(pageNo + 1);
  //   }
  // };

  // const handlePageClick = (pageNumber) => {
  //   setPageNo(pageNumber);
  // };

  const generatePageNumbers = () => {
    const totalPages = Math.ceil(totalCount / entries);
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <div
            key={i}
            className={`page-item ${pageNo === i ? "active" : ""}`}
            style={{
              backgroundColor: pageNo === i ? "#0971fe" : "",
              color: pageNo === i ? "#fff" : "",
            }}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </div>
        );
      }
    } else {
      if (pageNo <= 3) {
        for (let i = 1; i <= 5; i++) {
          pageNumbers.push(
            <div
              key={i}
              className={`page-item ${pageNo === i ? "active" : ""}`}
              style={{ backgroundColor: pageNo === i ? "#0971fe" : "" }}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </div>
          );
        }
        pageNumbers.push(
          <div key="ellipsis" className="page-item">
            ...
          </div>
        );
        pageNumbers.push(
          <div
            key={totalPages}
            className={`page-item ${pageNo === totalPages ? "active" : ""}`}
            style={{ backgroundColor: pageNo === totalPages ? "#0971fe" : "" }}
            onClick={() => handlePageClick(totalPages)}
          >
            {totalPages}
          </div>
        );
      } else if (pageNo >= totalPages - 2) {
        pageNumbers.push(
          <div
            key={1}
            className={`page-item ${pageNo === 1 ? "active" : ""}`}
            style={{ backgroundColor: pageNo === 1 ? "#0971fe" : "" }}
            onClick={() => handlePageClick(1)}
          >
            1
          </div>
        );
        pageNumbers.push(
          <div key="ellipsis" className="page-item">
            ...
          </div>
        );
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageNumbers.push(
            <div
              key={i}
              className={`page-item ${pageNo === i ? "active" : ""}`}
              style={{ backgroundColor: pageNo === i ? "#0971fe" : "" }}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </div>
          );
        }
      } else {
        pageNumbers.push(
          <div
            key={1}
            className={`page-item ${pageNo === 1 ? "active" : ""}`}
            style={{ backgroundColor: pageNo === 1 ? "#0971fe" : "" }}
            onClick={() => handlePageClick(1)}
          >
            1
          </div>
        );
        // pageNumbers.push(<div key="ellipsis" className="page-item">...</div>);
        for (let i = pageNo - 1; i <= pageNo + 1; i++) {
          pageNumbers.push(
            <div
              key={i}
              className={`page-item ${pageNo === i ? "active" : ""}`}
              style={{ backgroundColor: pageNo === i ? "#0971fe" : "" }}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </div>
          );
        }
        pageNumbers.push(
          <div key="ellipsis" className="page-item">
            ...
          </div>
        );
        pageNumbers.push(
          <div
            key={totalPages}
            className={`page-item ${pageNo === totalPages ? "active" : ""}`}
            style={{ backgroundColor: pageNo === totalPages ? "#0971fe" : "" }}
            onClick={() => handlePageClick(totalPages)}
          >
            {totalPages}
          </div>
        );
      }
    }

    return pageNumbers;
  };
  return (
    <div className="table-container">
      {top && (
        <div className="AO-2nd">
          {hideEntries ? (
            <div />
          ) : (
            <div className="AO-2nd-entries">
              Show
              <Select
                // className="AO-2nd-ent-select"
                value={entries}
                onChange={(e) => onEntriesChange(e)}
                size={"sm"}
                borderRadius={5}
                bg={"#fff"}
                paddingInline={2}
                // onChange={(e) => {
                //   setEntries(e.target.value);
                //   setPageNo(1);
                // }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value={totalCount}>All</option>
              </Select>
              entries
            </div>
          )}
          {/* <div style={{ width: "25%" }}> */}
          <Flex minW={["100%", "25%"]}>
            <Input
              value={SerchInput}
              onChange={(e) => onSearchChange(e.target.value)}
              placeholder={"Search..."}
            />
          </Flex>
          {/* </div> */}
        </div>
      )}

      {bottom && (
        <div className="Page">
          <div>
            Showing {entries * (pageNo - 1) + 1} to{" "}
            {entries * pageNo > totalCount ? totalCount : entries * pageNo} of{" "}
            {totalCount} entries
          </div>
          <div className="page-2nd">
            <div
              className={`page-prev ${pageNo === 1 ? "disabled" : ""}`}
              onClick={handlePrev}
            >
              Previous
            </div>
            {generatePageNumbers()}
            <div
              className={`page-next ${pageNo === 100 ? "disabled" : ""}`}
              onClick={handleNext}
            >
              Next
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
