import { FaArrowLeft } from "react-icons/fa";
import { Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const BackIcon = () => {
  const navigate = useNavigate();
  return (
    <Text
      onClick={() => navigate("/")} //
      cursor={"pointer"}
      color={"blue"}
      display={"flex"}
      alignItems={"center"}
      gap={2}
    >
      <FaArrowLeft /> Back
    </Text>
  );
};
