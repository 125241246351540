import { Card, Center, Container, Image, Text, VStack } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomInput from "../components/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import CustomButton1 from "../components/CustomButton1";
import { passwordRegex } from "../utils/utils";
import { handle_login } from "../utils/apis";
import store from "../redux/Store";
import { getPolicy_list } from "../redux/slice/policySlice";
import logoImg from "../assets/logo.png";
import { FaArrowLeft } from "react-icons/fa";
import { BackIcon } from "../components/Back/Back";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { getUserData } from "../redux/slice/userSlice";

const Login = () => {
  const inputRef = useRef();
  const loggedInUser = localStorage.getItem("user");
  const [showPass, setShowPass] = useState(false);
  const user = JSON.parse(loggedInUser);
  const navigate = useNavigate();
  const [id, setId] = useState({ value: "", validate: false, mess: "" });
  const [password, setPassword] = useState({
    value: "",
    validate: false,
    mess: "",
  });
  const [loading, setLoading] = useState(false);

  const validate = () => {
    if (id.value.length > 3) {
      if (passwordRegex.test(password.value)) {
        const obj = {
          email: id.value,
          password: password.value,
        };
        handle_login(obj, setLoading).then((v) => {
          if (v?.success) {
            store.dispatch(getPolicy_list(v?.consumer?.token));
            store.dispatch(getUserData(v?.consumer?.token));
            localStorage.setItem("user", JSON.stringify(v));
            window.location.reload();
            navigate("/home");
          }
        });
      } else {
        setPassword({
          ...password,
          validate: true,
          mess: "Password must be at least 8 characters long and contain at least  one special character !",
        });
      }
    } else {
      if (!passwordRegex.test(password.value)) {
        setPassword({
          ...password,
          validate: true,
          mess: "Password must be at least 8 characters long and contain at least  one special character !",
        });
      }
      setId({ ...id, validate: true, mess: "Invalid Email ID!" });
    }
  };
  useEffect(() => {
    user !== null && navigate("/home");
  }, []);

  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;

      if (keyCode === 13) {
        validate();
      }
    },
    [id, password]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);
  return (
    <Container
      w={"full"}
      h={"full"}
      minH={"100vh"}
      maxW={"full"}
      bg={
        "linear-gradient( 109.6deg, rgba(156,252,248,1) 11.2%, rgba(110,123,251,1) 91.1% )"
      }
      justifyContent={"center"}
      alignItems={"center"}
      display={["flex", "flex", "flex", "flex"]}
      // background={"url(bg.png) center/cover no-repeat"}
    >
      <VStack
        h={"full"}
        w={"50%"}
        justify={"center"}
        align={"center"}
        display={["none", "none", "flex", "flex"]}
      ></VStack>
      <VStack
        h={"full"}
        w={["full", "70%", "50%"]}
        justify={"center"}
        align={"center"}
      >
        <Card
          px={5}
          size={"lg"}
          w={"full"}
          maxW={"md"}
          justify={"center"}
          py={5}
        >
          <BackIcon />
          <Center>
            <Image
              src={logoImg}
              // h={"3rem"}
              w={"120px"}
              borderRadius={3}
              p={1}
            />
          </Center>
          <Text fontSize={"lg"} textAlign={"center"} mt={2}>
            Login
          </Text>

          <CustomInput
            placeholder="Email ID"
            value={id.value}
            onChange={(e) => {
              e.preventDefault();
              setId({
                ...id,
                value: e.target.value.trim().toLowerCase(),
                mess: "",
                validate: false,
              });
            }}
            isInvalid={id.validate}
            error={id.mess}
          />

          <CustomInput
            initialRef={inputRef}
            placeholder="Password"
            value={password.value}
            onChange={(e) => {
              e.preventDefault();
              setPassword({
                ...password,
                value: e.target.value.trim(),
                mess: "",
                validate: false,
              });
            }}
            isInvalid={password.validate}
            error={password.mess}
            type={showPass ? "text" : "password"}
            right={
              !showPass ? (
                <FiEye
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShowPass(!showPass)}
                />
              ) : (
                <FiEyeOff
                  cursor={"pointer"}
                  size={18}
                  onClick={() => setShowPass(!showPass)}
                />
              )
            }
          />
          <Text
            fontSize={["sm", "md"]}
            textAlign={"end"}
            mt={2}
            cursor={"pointer"}
            alignSelf={"end"}
            onClick={() => navigate("/reset-password")}
          >
            Forgot Password?
          </Text>
          <Center mt={4}>
            <CustomButton1
              id={"#submitButton"}
              title={"Login"}
              onClick={() => validate()}
              loading={loading}
            />
          </Center>
          <Link
            to={"/singup"}
            style={{
              textAlign: "center",
              marginTop: 10,
              fontSize: "16px",
              color: "blue",
              alignSelf: "center",
            }}
          >
            Create Account?
          </Link>
        </Card>
      </VStack>
    </Container>
  );
};

export default Login;
