import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

function BreadCrumName({ titlelist = [], to, current }) {
  return (
    <Breadcrumb>
      {titlelist.map((v, i) => (
        <BreadcrumbItem key={i}>
          <Link to={v.to}>
            <Text
              fontSize={["sm", "md", "lg"]}
              fontWeight="medium"
              color="#0f0d66"
              mb={0}
            >
              {v.name}
            </Text>
          </Link>
        </BreadcrumbItem>
      ))}
      {current && (
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            color={"#0f0d66"}
            href={to}
            fontSize={["xs", "sm", "md", "lg"]}
          >
            {current}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
}

export default BreadCrumName;
