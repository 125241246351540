import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/apis";

export const getNotifications = createAsyncThunk(
  "notification",
  async (token) => {
    try {
      const responce = await fetch(base_url + "notification/list/", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const res = await responce.json();
      return res;
    } catch (error) {
      console.log(error);
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notification_list: [],
    notification_count: 0,
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload;
      if (data?.success) {
        state.notification_list = data?.data;
        state.notification_count = data?.unreadCount;
      } else {
        state.user_data = [];
      }
    });
  },
});
export default notificationSlice.reducer;
