import {
  Center,
  Container,
  Stack,
  Text,
  Link,
  VStack,
  Card,
  Image,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import CustomInput from "../components/CustomInput";
import CustomButton1 from "../components/CustomButton1";
import { emailRegex, errorMess, successMess } from "../utils/utils";
import { forgot_pass } from "../utils/apis";
import { useNavigate } from "react-router-dom";
import logoImg from "../assets/logo.png";
import { BackIcon } from "../components/Back/Back";

const Login = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    show_otp: false,
  });

  const sendOtp = () => {
    if (!data.contact) {
      setData({
        ...data,
        con_mess: "Please enter your email/mobile number",
        con_validate: true,
      });
      return;
    }
    if (data.contact.includes("@")) {
      if (!emailRegex.test(data.contact)) {
        setData({
          ...data,
          con_mess: "Please enter valid email address",
          con_validate: true,
        });
        return;
      }
    }
    if (!data.contact.includes("@")) {
      if (data.contact.replace(/\D/g, "").length < 10) {
        setData({
          ...data,
          con_mess: "Please enter valid email/mobile number",
          con_validate: true,
        });
        return;
      }
    }

    forgot_pass(data.contact, setLoading).then((v) => {
      if (v.success) {
        successMess(v.message);
        navigate("/login");
      } else {
        errorMess(v.message);
      }
    });
  };

  useEffect(() => {
    user !== null && navigate("/"); //
  }, []);
  return (
    <Container
      w={"full"}
      h={"100vh"}
      maxW={"full"}
      bg={
        "linear-gradient( 109.6deg, rgba(156,252,248,1) 11.2%, rgba(110,123,251,1) 91.1% )"
      }
      justifyContent={"center"}
      alignItems={"center"}
      display={["flex", "flex", "flex", "flex"]}
    >
      <VStack
        h={"full"}
        w={"50%"}
        justify={"center"}
        align={"center"}
        display={["none", "none", "flex", "flex"]}
      ></VStack>
      <VStack
        h={"full"}
        w={["full", "70%", "50%"]}
        justify={"center"}
        align={"center"}
      >
        <Card
          px={5}
          size={"lg"}
          w={"full"}
          maxW={"md"}
          justify={"center"}
          py={5}
        >
          <BackIcon />
          <Center>
            <Image
              src={logoImg}
              // h={"3rem"}
              w={"120px"}
              borderRadius={3}
              p={1}
            />
          </Center>

          <Text fontSize={"lg"} textAlign={"center"} mt={2}>
            Reset Password
          </Text>
          <CustomInput
            label={"Email"}
            placeholder={"Enter Email"}
            value={data.contact}
            onChange={(e) =>
              setData({
                ...data,
                contact: e.target.value.trim().toLowerCase(),
                con_mess: "",
                con_validate: false,
              })
            }
            isInvalid={data.con_validate}
            error={data.con_mess}
            rightButton={
              <CustomButton1
                mt={1}
                title={"Submit"}
                loading={loading == 2 ? true : false}
                onClick={sendOtp}
              />
            }
          />
          <Link
            onClick={() => navigate("/login")}
            style={{
              textAlign: "center",
              marginTop: 10,
              fontSize: "16px",
              color: "blue",
              alignSelf: "center",
            }}
          >
            Login?
          </Link>
        </Card>
      </VStack>
    </Container>
  );
};

export default Login;
