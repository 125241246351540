import React from "react";
import styles from "./planCmp.module.scss";

const PlanCmp = ({
  planName,
  price,
  days,
  line1,
  line2,
  line3,
  line4,
  line5,
  b_name,
  onClick,
}) => {
  return (
    <div className={styles.card_main}>
      <div className={styles.card_heading}>{planName}</div>
      <div className={styles.card_price_div}>
        <div className={styles.card_price1}>Rs </div>
        <div className={styles.card_price2}>{price}</div>
        <div className={styles.card_price3}>/{days} days</div>
      </div>
      <div className={styles.card_second}>
        <div className={styles.card_second_heading}>{line1}</div>
        <div className={styles.card_second_heading}>{line2}</div>
        <div className={styles.card_second_heading}>{line3}</div>
        <div className={styles.card_second_heading}>{line4}</div>
        <div className={styles.card_second_heading} style={{ border: "none" }}>
          {line5}
        </div>
        {/* <div
          style={{ display: "flex", flex: 1, width: "100%", height: "100%" }}
        ></div> */}
        <div className={styles.card_button} onClick={onClick}>
          {b_name}
        </div>
      </div>
    </div>
  );
};

export default PlanCmp;
