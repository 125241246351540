import React, { useEffect, useRef } from "react";
import {
  Button,
  Card,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import store from "../redux/Store";
import { getNotifications } from "../redux/slice/notificationSlice";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";

const NotificationHeader = () => {
  const [isSmallThan375] = useMediaQuery("(max-width: 375px)");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { notification_list, loading, notification_count } = useSelector(
    (state) => state.notifications
  );
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const token = user?.consumer?.token;
  const ref = useRef(null);
  useEffect(() => {
    const interval = setInterval(
      () => token && store.dispatch(getNotifications(token)),
      30000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Popover placement="bottom-end" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          borderRadius={"full"}
          bg={"transparent"}
          px={0}
          _hover={{ bg: "transparent" }}
          onClick={onOpen}
          outline={"none"}
          border={"none"}
          position={"relative"}
        >
          <IoMdNotificationsOutline
            size={25}
            style={{
              backgroundColor: "#fff",
              height: isSmallThan375 ? 20 : 30,
              width: isSmallThan375 ? 20 : 30,
              padding: 4,
              borderRadius: 20,
            }}
          />
          {notification_count > 0 && (
            <div
              style={{
                position: "absolute",
                backgroundColor: "blue",
                color: "white",
                top: "-2px",
                right: "-7px",
                borderRadius: "50%",
                height: "21px",
                width: "22px",
                color: "white",
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {notification_count}
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          height={"350px"}
          overflowY={"scroll"}
          maxW={["220px", "350px", "md", "xl"]}
        >
          <PopoverArrow />
          <PopoverHeader>Notifications</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody onClick={() => onClose()}>
            {notification_list?.length != 0 && loading ? (
              <Loader />
            ) : notification_list?.length == 0 ? (
              <Text textAlign={"center"} color={"#000"} fontSize={"md"}>
                No Notification available
              </Text>
            ) : (
              notification_list?.slice(0, 5).map((v, i) => (
                <Card
                  key={i}
                  mb={2}
                  py={2}
                  px={3}
                  cursor={"pointer"}
                  onClick={() => navigate("/notification-detail", { state: v })}
                >
                  <Text
                    mb={0}
                    fontSize={["sm", "md"]}
                    fontWeight={"semibold"}
                    color={"#000"}
                  >
                    {v?.message}
                  </Text>

                  {/* <Text
                    mb={0}
                    fontSize={["xs", "sm"]}
                    fontWeight={"normal"}
                    color={"#000"}
                    noOfLines={1}
                  >
                    {v.message}
                  </Text> */}
                </Card>
              ))
            )}
            {notification_list.length == 0 || (
              <Text
                cursor={"pointer"}
                textAlign={"right"}
                mb={0}
                my={2}
                fontSize={["sm", "md"]}
                fontWeight={"semibold"}
                color={"blue"}
                onClick={() => {
                  navigate("/all-notifications");
                }}
              >
                See More
              </Text>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default NotificationHeader;
