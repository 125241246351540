import {
  Checkbox,
  Container,
  Flex,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../components/BreadCrumName";
import CustomInput from "../components/CustomInput";
import CustomButton1 from "../components/CustomButton1";
import { useLocation, useNavigate } from "react-router-dom";
import { base_url } from "../utils/apis";
import { toast } from "react-toastify";
import store from "../redux/Store";
import { getUserData } from "../redux/slice/userSlice";
import { useSelector } from "react-redux";

const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { user_data } = useSelector((state) => state.user);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const token = user?.consumer?.token;
  const [planDetail, setPlanDetail] = useState({});
  const [data, setData] = useState({
    terms: true,
    terms1: true,
  });

  const purchase_plane = async () => {
    if (
      !planDetail.FullName ||
      !planDetail.Pincode ||
      !planDetail.Email_ID ||
      !planDetail.Mobile_Number ||
      !planDetail.City
    ) {
      toast.info("Please fill all fields");
      return;
    }
    if (data.terms != false || data.terms1 != false) {
      toast.info("Please check both conditions");
      return;
    }

    try {
      setLoading(true);
      const raw = JSON.stringify({
        FullName: planDetail.FullName,
        Pincode: planDetail.Pincode,
        City: planDetail.City,
        Email_ID: planDetail.Email_ID,
        Mobile_Number: planDetail.Mobile_Number,
        GST_Number: !planDetail.GST_Number ? "" : planDetail.GST_Number,
      });
      const response = await fetch(
        base_url + `recharge//purchase/plan-id/${location.state.plan_id}`,
        {
          method: "POST",
          body: raw,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
      setLoading(false);
      if (res.success) {
        window.open(res.url, "_self");
      } else {
        alert(res.message);
      }
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };
  const get_history = async () => {
    try {
      const response = await fetch(
        base_url + `recharge/list/user_id/${user?.consumer?.user_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();

      if (res.success) {
        setPlanDetail(res.list?.length == 0 ? setPlanDetail({}) : res.list[0]);
      } else {
        setPlanDetail({});
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // get_history();
    store
      .dispatch(getUserData(token))
      .unwrap()
      .then((v) => {
        if (v.success) {
          setPlanDetail({
            ...planDetail,
            FullName: v?.data?.full_name,
            Pincode: "",
            City: "",
            Email_ID: v?.data?.email_address,
            Mobile_Number: v?.data?.mobile,
          });
        } else {
          setPlanDetail({
            ...planDetail,
            FullName: "",
            Pincode: "",
            City: "",
            Email_ID: "",
            Mobile_Number: "",
          });
        }
      });
  }, []);

  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <BreadCrumName
        current={"Checkout"}
        titlelist={[{ name: "Home", to: "/home" }]}
      />
      <Container
        maxW={"container.md"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Text
          mb={0}
          fontSize={["md", "lg", "xl", "2xl"]}
          fontWeight={"medium"}
          color={"#0f0d66"}
        >
          Proceed to secure Checkout
        </Text>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Text
            mb={0}
            fontSize={["md", "lg"]}
            fontWeight={"normal"}
            color={"#000"}
          >
            Plan Details
          </Text>
          <Text
            mb={0}
            fontSize={["md", "lg"]}
            fontWeight={"normal"}
            color={"#000"}
          >
            Ammount
          </Text>
        </Flex>
        <Flex justifyContent={"space-between"} alignItems={"center"} my={2}>
          <Text mb={0} fontSize={["md"]} fontWeight={"normal"} color={"#000"}>
            Rs {location.state?.plan_price} / {location.state?.plan_duration}{" "}
            days
          </Text>
          <Text mb={0} fontSize={["md"]} fontWeight={"normal"} color={"#000"}>
            ₹ {location.state.plan_price}
          </Text>
        </Flex>
        <Flex justifyContent={"space-between"} bg={"#f1f1f1"} padding={4}>
          <Text
            mb={0}
            fontSize={["md", "lg"]}
            fontWeight={"medium"}
            color={"#000"}
          >
            Total
          </Text>
          <Text
            mb={0}
            fontSize={["md", "lg"]}
            fontWeight={"medium"}
            color={"#000"}
          >
            ₹ {location.state.plan_price}
          </Text>
        </Flex>
        <Text
          mt={5}
          fontSize={["md", "lg", "xl", "2xl"]}
          fontWeight={"medium"}
          color={"#0f0d66"}
        >
          Details
        </Text>
      </Container>

      <Container
        w={"full"}
        maxW={"md"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CustomInput
          label={"Full Name*"}
          placeholder={"Full Name"}
          value={planDetail?.FullName}
          onChange={(e) => {
            setPlanDetail({
              ...planDetail,
              FullName: e.target.value
                .trimStart()
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .replace(/^[0-9]/, "")
                .replace(/\s{2,}/g, " "),
            });
          }}
        />
        <CustomInput
          label={"Pincode*"}
          placeholder={"Pincode"}
          value={planDetail?.Pincode}
          maxL={6}
          onChange={(e) => {
            setPlanDetail({
              ...planDetail,
              Pincode: e.target.value.trim().replace(/[^0-9 ]/g, ""),
            });
          }}
        />
        <CustomInput
          label={"City*"}
          placeholder={"City"}
          value={planDetail?.City}
          onChange={(e) => {
            setPlanDetail({
              ...planDetail,
              City: e.target.value
                .trimStart()
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .replace(/^[0-9]/, "")
                .replace(/\s{2,}/g, " "),
            });
          }}
        />
        <CustomInput
          label={"Email ID*"}
          placeholder={"Email ID"}
          value={planDetail?.Email_ID}
          onChange={(e) => {
            setPlanDetail({
              ...planDetail,
              Email_ID: e.target.value.trim(),
            });
          }}
        />
        <CustomInput
          label={"Mobile Number*"}
          placeholder={"Mobile Number"}
          value={planDetail?.Mobile_Number}
          maxL={10}
          onChange={(e) => {
            setPlanDetail({
              ...planDetail,
              Mobile_Number: e.target.value.trim().replace(/[^0-9 ]/g, ""),
            });
          }}
        />
        <CustomInput
          label={"GST Number(Optional)"}
          placeholder={"GST Number"}
          value={planDetail?.GST_Number}
          maxL={15}
          onChange={(e) => {
            setPlanDetail({
              ...planDetail,
              GST_Number: e.target.value.trim().replace(/[^a-zA-Z0-9 ]/g, ""),
            });
          }}
        />
        <Flex gap={3} mt={5}>
          <Checkbox
            checked={data.terms}
            onChange={(e) => setData({ ...data, terms: !data.terms })}
          />
          <Text
            mb={0}
            fontSize={["md", "lg"]}
            fontWeight={"normal"}
            color={"#000"}
          >
            I accept the{" "}
            <Link
              fontWeight={"medium"}
              color={"#0f0d66"}
              onClick={() => navigate("/terms-condition")}
            >
              Terms And Conditions*
            </Link>
          </Text>
        </Flex>
        <Flex gap={3} mt={5}>
          <Checkbox
            color={"#0f0d66"}
            checked={data.terms1}
            onChange={(e) => setData({ ...data, terms1: !data.terms1 })}
          />
          <Text
            mb={0}
            fontSize={["md", "lg"]}
            fontWeight={"normal"}
            color={"#000"}
          >
            I wish to recevie communication on the{" "}
            <Link fontWeight={"medium"} color={"#0f0d66"}>
              SmartDocs365
            </Link>{" "}
            products and services via whatsapp
          </Text>
        </Flex>
        <Flex justifyContent={"center"} mt={5}>
          <CustomButton1
            title={`Proceed to Pay ₹ ${location.state.plan_price}`}
            onClick={() => purchase_plane()}
            loading={loading}
          />
        </Flex>
      </Container>
    </Container>
  );
};

export default Checkout;
