import React from "react";
import "../MainPage/mainpage.css";
import { FooterData, HeaderData } from "../MainPage/MainPage";
import moment from "moment";

export const Privacy = () => {
  return (
    <>
      <HeaderData />
      <div id="static_privacy_page" className="page-container">
        <div
          className="fixed-width"
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          <h1
            className="page-title"
            style={{ fontSize: "24px", fontWeight: "bold" }}
          >
            Smartdocs365 Privacy Policy{" "}
            <span className="small">
              as of {moment(new Date()).format("MMM DD, YYYY")}
            </span>
          </h1>

          <div
            className="fixed-width"
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "1rem",
            }}
          >
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              INFORMATION COLLECTION AND USE BY SmartDocs365
            </h3>
            <p>
              SmartDocs365.com ("SmartDocs365") collects information about users
              such as Name, Email address, Phone numbers etc. This information
              is never presented or displayed to other users on SmartDocs365
              site and never shared with any third party. It is solely used for
              authenticating users to use the services provided by
              SmartDocs365.com. Other Information such as address and credit
              card are solely used for making payment to SmartDocs365 when users
              recharge their account. The credit card numbers may be encrypted
              and never shared with any other party.
            </p>
            <p>
              SmartDocs365 also logs some identifiable and some
              non-personally-identifiable information including IP address,
              preferences, aggregate user data, and browser type, from users and
              visitors to the site. This data is used to manage the website,
              track usage and improve the website services. This
              non-personally-identifiable information may be shared with
              third-parties to provide more relevant services and advertisements
              to members. But identifiable information is never shared with
              third parties. User IP addresses are recorded for security and
              monitoring purposes.
            </p>
            <p>
              SmartDocs365 may also sometimes send updates and important
              notifications regarding the service to the user's email address.
              From time to time, SmartDocs365.com or a partner may sponsor a
              promotion, sweepstake or contest on SmartDocs365.com Users may be
              asked to provide personal information including name, email
              address or home address or to answer questions in order to
              participate. SmartDocs365 may transfer personal information to
              certain ad partners that you have explicitly requested to receive
              information from. It will be clear at the point of collection who
              is collecting the personal information and whose privacy statement
              will apply. If the user has registered on SmartDocs365.com and
              used a coupon or gift code provided by either SmartDocs365 or one
              of our partners or affiliate site, then the partner may contact
              the user to get feedback on the service and potentially offer
              other promotions on time to time via phone, mail or email.
            </p>
            <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
              USE OF COOKIES
            </h3>
            <p>
              SmartDocs365 may use cookies for purposes such as but not limited
              to store visitors' preferences and to record session information.
              You may be able to configure your browser to accept or reject all
              or some cookies, or notify you when a cookie is set -- each
              browser is different, so check the "Help" menu of your browser to
              learn how to change your cookie preferences -- however, you should
              enable cookies from SmartDocs365 in order to use most functions on
              the site. Please note that SmartDocs365 allows 3rd party
              advertisers that are presenting advertisements on some of our
              pages to set and access their cookies on your computer.
              Advertisers' use of cookies is subject to their own privacy
              policies, not the SmartDocs365 Privacy Policy.
            </p>
          </div>
          <br />
          <br />
        </div>
      </div>
      <FooterData />
    </>
  );
};
