import { Center, Container, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../components/BreadCrumName";
import CustomInput from "../../components/CustomInput";
import { useSelector } from "react-redux";
import CustomButton1 from "../../components/CustomButton1";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { emailRegex, passwordRegex } from "../../utils/utils";
import store from "../../redux/Store";
import { getUserData, userId } from "../../redux/slice/userSlice";
import { useNavigate } from "react-router-dom";
import { update_password_api, update_profile_api } from "../../utils/apis";
import { LoaderFull } from "../../components/Loader/LoaderFull";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

const Profile = () => {
  const navigate = useNavigate();
  const {
    user_data,
    user_plan_data,
    user_loading,
    current_plan_id,
    plan_list,
  } = useSelector((state) => state.user);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const [u_data, setU_data] = useState({});
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [show, setShow] = React.useState({
    one: false,
    two: false,
    three: false,
  });
  const [pass, setPass] = useState({
    current: "",
    current_mess: "",
    current_validate: false,
    new: "",
    new_mess: "",
    new_validate: false,
    c_new: "",
    c_new_mess: "",
    c_new_validate: false,
  });

  const token = user?.consumer?.token;
  const userId = user?.consumer?.user_id;

  useEffect(() => {
    store.dispatch(getUserData(token));
    setU_data(user_data);
  }, []);

  const change_pass = async () => {
    if (
      !pass.current ||
      !pass.new ||
      !pass.c_new ||
      !passwordRegex.test(pass.new) ||
      pass.new !== pass.c_new
    ) {
      setPass({
        ...pass,
        current_mess: !pass.current ? "Current password is required" : "",
        current_validate: !pass.current ? true : false,
        new_mess: !pass.new
          ? "New password is required"
          : !passwordRegex.test(pass.new)
          ? "Password must be at least 8 characters long and contain at least  one special character !"
          : "",
        new_validate: !pass.new || !passwordRegex.test(pass.new) ? true : false,
        c_new_mess: !pass.c_new
          ? "Confirm password is required"
          : pass.new !== pass.c_new
          ? "Confirm Password does not match New Password"
          : "",
        c_new_validate: !pass.c_new || pass.new !== pass.c_new ? true : false,
      });
      return;
    }
    try {
      setShowLoader(true);
      const obj = {
        user_id: userId,
        old_password: pass?.current,
        new_password: pass?.new,
      };
      const response = await update_password_api(obj);
      setShowLoader(false);
      if (response?.success) {
        navigate("/home");
        toast.success(response.message);
        setShowPass(false);
      } else if (!response.succes) {
        alert(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const update_profile = async () => {
    if (!u_data.first_name) {
      setU_data({
        ...u_data,
        first_name_mess: "First name is required",
        first_name_validate: true,
      });
      return;
    }
    if (!u_data.last_name) {
      setU_data({
        ...u_data,
        last_name_mess: "Last name is required",
        last_name_validate: true,
      });
      return;
    }
    if (!u_data.mobile) {
      setU_data({
        ...u_data,
        mobile_mess: "Mobile is required",
        mobile_validate: true,
      });
      return;
    }
    if (!u_data.email_address) {
      setU_data({
        ...u_data,
        email_address_mess: "Email address is required",
        email_address_validate: true,
      });
      return;
    }
    if (!emailRegex.test(u_data.email_address)) {
      setU_data({
        ...u_data,
        email_address_mess: "Invalid Email address",
        email_address_validate: true,
      });
      return;
    }
    try {
      setShowLoader(true);
      const obj = {
        first_name: u_data?.first_name,
        last_name: u_data?.last_name,
        email_address: u_data?.email_address,
        mobile: u_data?.mobile,
      };
      const response = await update_profile_api(obj);
      setShowLoader(false);
      if (response?.success) {
        navigate("/home");
        toast.success(response.message);
        setEdit(false);
        store.dispatch(getUserData());
      } else if (!response.succes) {
        alert(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditProfile = () => {
    setEdit(!edit);
    setU_data({
      ...u_data,
      first_name: user_data?.first_name,
      last_name: user_data?.last_name,
      email_address: user_data?.email_address,
      mobile: user_data?.mobile,
    });
  };

  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <BreadCrumName
        current={"Profile"}
        titlelist={[{ name: "Home", to: "/home" }]}
      />
      {user_loading ? (
        <Loader />
      ) : (
        <>
          <Container
            maxW={"container.md"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              mb={0}
              fontSize={["md", "lg", "xl", "2xl"]}
              fontWeight={"medium"}
              color={"#0f0d66"}
            >
              Profile Detail
            </Text>
          </Container>
          <Container
            w={"full"}
            maxW={"md"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CustomInput
              textonly={true}
              label={"First Name"}
              value={user_data?.first_name}
              isInvalid={u_data?.first_name_validate}
              error={u_data?.first_name_mess}
              onChange={(e) => {
                setU_data({
                  ...u_data,
                  first_name: e.target.value,
                  first_name_mess: "",
                  first_name_validate: false,
                });
              }}
            />
            <CustomInput
              textonly={true}
              label={"Last Name"}
              value={user_data?.last_name}
              isInvalid={u_data?.last_name_validate}
              error={u_data?.last_name_mess}
              onChange={(e) => {
                setU_data({
                  ...u_data,
                  last_name: e.target.value,
                  last_name_mess: "",
                  last_name_validate: false,
                });
              }}
            />
            <CustomInput
              textonly={true}
              label={"Email"}
              value={user_data?.email_address}
              isInvalid={u_data?.email_address_validate}
              error={u_data?.email_address_mess}
              onChange={(e) => {
                setU_data({
                  ...u_data,
                  email_address: e.target.value,
                  email_address_mess: "",
                  email_address_validate: false,
                });
              }}
            />
            <CustomInput
              textonly={!edit}
              label={"Mobile"}
              value={edit ? u_data.mobile : user_data?.mobile}
              isInvalid={u_data?.mobile_validate}
              error={u_data?.mobile_mess}
              maxL={10}
              onChange={(e) => {
                setU_data({
                  ...u_data,
                  mobile: e.target.value.trim().replace(/[^0-9 ]/g, ""),
                  mobile_mess: "",
                  mobile_validate: false,
                });
              }}
            />
            <Center mt={3}>
              <CustomButton1
                title={edit ? "Cancel" : "Edit"}
                onClick={handleEditProfile}
                bg={edit ? "gray" : "blue"}
                loading={loading == "pro" ? true : false}
              />

              {edit && (
                <CustomButton1
                  title={"Update"}
                  onClick={update_profile}
                  loading={loading == "pro" ? true : false}
                />
              )}
              {!showPass && (
                <CustomButton1
                  title={"Change Password"}
                  onClick={() => setShowPass(!showPass)}
                  bg={"green"}
                  loading={loading == "pro" ? true : false}
                />
              )}
            </Center>
          </Container>

          {showPass && (
            <>
              <Container
                maxW={"container.md"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text
                  mb={0}
                  fontSize={["md", "lg", "xl", "2xl"]}
                  fontWeight={"medium"}
                  color={"#0f0d66"}
                >
                  Change Password{" "}
                </Text>
              </Container>
              <Container
                w={"full"}
                maxW={"md"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CustomInput
                  placeholder="Password"
                  type={show.one ? "text" : "password"}
                  value={pass.current}
                  label={"Current Password*"}
                  onChange={(e) =>
                    setPass({
                      ...pass,
                      current: e.target.value,
                      current_mess: "",
                      current_validate: false,
                    })
                  }
                  isInvalid={pass.current_validate}
                  error={pass.current_mess}
                  right={
                    !show.one ? (
                      <FiEye
                        cursor={"pointer"}
                        size={18}
                        onClick={() => setShow({ ...show, one: !show.one })}
                      />
                    ) : (
                      <FiEyeOff
                        cursor={"pointer"}
                        size={18}
                        onClick={() => setShow({ ...show, one: !show.one })}
                      />
                    )
                  }
                />
                <CustomInput
                  placeholder="Password"
                  type={show.two ? "text" : "password"}
                  value={pass.new}
                  label={"New Password*"}
                  onChange={(e) =>
                    setPass({
                      ...pass,
                      new: e.target.value,
                      new_mess: "",
                      new_validate: false,
                    })
                  }
                  isInvalid={pass.new_validate}
                  error={pass.new_mess}
                  right={
                    !show.two ? (
                      <FiEye
                        cursor={"pointer"}
                        size={18}
                        onClick={() => setShow({ ...show, two: !show.two })}
                      />
                    ) : (
                      <FiEyeOff
                        cursor={"pointer"}
                        size={18}
                        onClick={() => setShow({ ...show, two: !show.two })}
                      />
                    )
                  }
                />
                <CustomInput
                  placeholder="Password"
                  type={show.three ? "text" : "password"}
                  value={pass.c_new}
                  label={"Confirm New Password*"}
                  onChange={(e) =>
                    setPass({
                      ...pass,
                      c_new: e.target.value,
                      c_new_mess: "",
                      c_new_validate: false,
                    })
                  }
                  isInvalid={pass.c_new_validate}
                  error={pass.c_new_mess}
                  right={
                    !show.three ? (
                      <FiEye
                        cursor={"pointer"}
                        size={18}
                        onClick={() => setShow({ ...show, three: !show.three })}
                      />
                    ) : (
                      <FiEyeOff
                        cursor={"pointer"}
                        size={18}
                        onClick={() => setShow({ ...show, three: !show.three })}
                      />
                    )
                  }
                />

                <Center mt={3}>
                  <CustomButton1
                    title={"Update"}
                    onClick={change_pass}
                    loading={loading == "pass" ? true : false}
                  />
                  <CustomButton1
                    title={"Cancel"}
                    bg={"gray"}
                    onClick={() => setShowPass(false)}
                    loading={loading == "pass" ? true : false}
                  />
                </Center>
              </Container>
            </>
          )}
          <Container
            maxW={"container.md"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              mb={0}
              fontSize={["md", "lg", "xl", "2xl"]}
              fontWeight={"medium"}
              color={"#0f0d66"}
            >
              Plan Detail
            </Text>
            <Text
              mb={0}
              fontSize={["md", "lg"]}
              fontWeight={"medium"}
              color={"#000"}
              _hover={{ color: "#0f0d66", textDecorationLine: "underLine" }}
              cursor={"pointer"}
              onClick={() => navigate("/transaction-history")}
            >
              View Transaction History
            </Text>
          </Container>

          <Container
            w={"full"}
            maxW={"md"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CustomInput
              textonly={true}
              label={"Plan Name"}
              // value={user_plan_data?.planName}
              value={user_plan_data?.planName?.split("  ")?.at(-1)?.trim()}
            />
            <div>
              Your Payment of Rs {user_plan_data?.planPrice} will be billed on{" "}
              {moment(user_plan_data?.rechargeExpiredDate).format("ll")}
            </div>
            <CustomInput
              textonly={true}
              label={"Total Limit"}
              value={user_plan_data?.totalPdfLimit}
            />
            <CustomInput
              textonly={true}
              label={"Used Limit"}
              value={user_plan_data?.usedLimit}
            />
            <CustomInput
              textonly={true}
              label={"Left Limit"}
              value={user_plan_data?.leftLimit}
            />
            <CustomInput
              textonly={true}
              label={"Recharge Date"}
              value={moment(user_plan_data?.rechargeDate).format("DD/MM/YYYY")}
            />
            <CustomInput
              textonly={true}
              label={"Recharge Expiry Date"}
              value={moment(user_plan_data?.rechargeExpiredDate).format(
                "DD/MM/YYYY"
              )}
            />
          </Container>
        </>
      )}
      {showLoader && <LoaderFull loading={showLoader} />}
    </Container>
  );
};

export default Profile;

// toast({
//   title: `${position} toast`,
//   position: position,
//    status: "success",
//   isClosable: true,
// })

// {"success":true,"message":"Logged Successfully!","consumer":{"token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiOTY4ZTIwMmEtODM1ZS00Y2Y5LWJlNjQtM2NkYTk2YTk2YTEzIiwicm9sZSI6InVzZXIiLCJpYXQiOjE3MDMzOTYzODd9.LPbq7xQKP7g_1EWBB1Pjmz0roKiQ5k9tIozc7Ongu28","user_id":"968e202a-835e-4cf9-be64-3cda96a96a13","role":"user","totalPdfLimit":10,"usedLimit":0,"leftLimit":10,"planName":"Free Plan","rechargeDate":"2023-12-24T00:37:14.000Z","rechargeExpiredDate":"2024-01-08"}}
