import {
  Box,
  Center,
  Container,
  HStack,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Flex } from "antd";
import React, { useEffect, useState } from "react";
import "./Blogs.css";
import { blogs_list_api, blogs_list_api2 } from "../../utils/apis";
import { upload_url } from "../../screens/home/Policy";

export const Bloags = () => {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const responce = await blogs_list_api();
      if (responce.success) {
        setBlogData(responce?.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Flex align={"center"} justify={"space-between"} mb={5}>
        <Heading
          fontSize={["md", "lg", "xl", "2xl"]}
          fontWeight={"medium"}
          color={"#0f0d66"}
          paddingLeft={"1rem"}
        >
          Blog
        </Heading>
      </Flex>
      <Container maxW={"container.md"} justifyContent={"center"}>
        {blogData?.map((item, index) => (
          <HStack
            bg={"#fff"}
            mt={5}
            p={4}
            flexDirection={["column", "row", "row"]}
            borderRadius={10}
          >
            <Stack flex={1}>
              <Text color={"#000"} fontWeight={600} fontSize={16}>
                {item?.title}
              </Text>
              <Text color={"#000"} fontWeight={400} fontSize={14}>
                {item?.description}
              </Text>
              {item?.videoUrl && (
                <Link
                  onClick={() => window.open(item?.videoUrl)}
                  cursor={"pointer"}
                  alignSelf={"start"}
                  color={"#0f0d66"}
                >
                  Open Link
                </Link>
              )}
            </Stack>
            {item?.imageUrl && (
              <Image
                src={`${upload_url}${item?.imageUrl}`}
                alt="user__image"
                h={32}
                w={32}
                objectFit={"contain"}
              />
            )}
          </HStack>
        ))}
      </Container>
    </>
  );
};
