import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/apis";
import { ID } from "../../utils/utils";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
export const token = user?.consumer?.token;
export const userId = user?.consumer?.user_id;
export const getUserData = createAsyncThunk("user", async (token) => {
  try {
    const response = await fetch(base_url + "user/profile/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const res = await response.json();

    return res;
  } catch (error) {
    console.log(error);
  }
});

export const get_plan_subcription = createAsyncThunk("plan", async () => {
  try {
    const response = await fetch(base_url + "subcription-plan-direct/list/", {
      method: "GET",
    });

    const res = await response.json();

    return res;
  } catch (error) {
    console.log(error);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    user_data: {},
    user_loading: false,
    plan_list: [],
    plan_loading: false,
    user_plan_data: {},
    current_plan_id: "",
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.pending, (state, action) => {
      state.user_loading = true;
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.user_loading = false;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.user_loading = false;
      const data = action.payload;
      if (data?.success) {
        state.user_data = data?.data;
        state.user_plan_data = data?.other;
        state.current_plan_id = data?.currentPlanID;
      } else {
        state.user_data = {};
      }
    });

    // Plan List Data

    builder.addCase(get_plan_subcription.pending, (state, action) => {
      state.plan_loading = true;
    });
    builder.addCase(get_plan_subcription.rejected, (state, action) => {
      state.plan_loading = false;
    });
    builder.addCase(get_plan_subcription.fulfilled, (state, action) => {
      state.plan_loading = false;
      const data = action.payload;
      if (data?.success) {
        state.plan_list = data.data;
      } else {
        state.plan_list = [];
      }
    });
  },
});
export default userSlice.reducer;

//
