import moment from "moment";
import { toast } from "react-toastify";
// export const passwordRegex = /[a-zA-Z0-9]+/;
export const passwordRegex = /[!@#$%^&*()_\-+=<>?/{}[\]|]/;
// export const passwordRegex = /^(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

export const successMess = (message) => {
  toast.success(message);
};
export const errorMess = (message) => {
  toast.error(message);
};

export const infoMess = (message) => {
  toast.info(message);
};

export const getMyLocation = async () => {
  const location = window.navigator && window.navigator.geolocation;

  if (location) {
    location.getCurrentPosition(
      (position) => {
        return {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      },
      (error) => {
        return false;
      },
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
    );
  }
};

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
export const ID = user?.userid;

export function parseDateWithFormats(dateString) {
  const outputFormat = "DD/MM/YYYY";
  const dateFormats = ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY"];
  let parsedDate = null;

  for (let format of dateFormats) {
    const parsedMoment = moment(dateString, format, true); // true to enable strict parsing
    if (parsedMoment.isValid()) {
      parsedDate = parsedMoment.format(outputFormat);
      break;
    }
  }

  return parsedDate;
}
export const isValidDateFormat = (dateString) => {
  const regex = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/;
  return regex.test(dateString);
};

export const formatDate = (dateString) => {
  // Remove any non-numeric characters and add slashes between day, month, and year
  const formattedDate = dateString
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
  return formattedDate;
};

export const DownloadPDFfile = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", true);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
};

export const downloadImage = async (originalImage) => {
  const image = await fetch(originalImage);
  const nameSplit = originalImage.split("/home");
  const duplicateName = nameSplit.pop();

  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);
  const link = document.createElement("a");
  link.href = imageURL;
  link.download = "" + duplicateName + "";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// export const downloadImage = (imageUrl) => {
//   fetch(imageUrl)
//     .then((response) => response.blob())
//     .then((blob) => {
//       // Create a temporary anchor element
//       const url = window.URL.createObjectURL(blob);
//       const link = document.createElement("a");
//       link.href = url;

//       // Extract the filename from the URL
//       const filename = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);

//       // Set the download attribute and filename
//       link.setAttribute("download", filename);
//       document.body.appendChild(link);

//       // Simulate a click on the anchor element to start the download
//       link.click();

//       // Clean up the temporary anchor element
//       link.parentNode.removeChild(link);

//       // Set the downloaded image URL to display on the page
//       // setImageUrl(url);
//     })
//     .catch((error) => {
//       console.error("Error downloading image:", error);
//     });
// };
